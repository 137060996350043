import {
  Switch,
  Route,
  useRouteMatch,
  Redirect
} from 'react-router-dom';
import CompleteWithEmail from './complete-with-email';
import Complete from './complete'
import Checkout from './checkout';
import CheckoutProvider from './checkout.provider';
import { useSelector } from 'react-redux'
import { enable_payments } from 'features/app-config/appConfigSlice'

const Payment = () => {
  let { path } = useRouteMatch();
  const payments_enabled = useSelector(enable_payments)
  console.log('payments_enabled', payments_enabled)
  return (
    <CheckoutProvider>
      <Switch>
        <Route exact path={path}>
          {payments_enabled ? <Checkout/> : <CompleteWithEmail/>}
        </Route>
        <Route path={`${path}/complete`}>
          <Complete/>
        </Route>
      </Switch>
    </CheckoutProvider>
  );
}

export default Payment;