import React, { useContext, useState } from 'react'
import Tile from 'app/shared/tile'
import { Table } from 'app/shared/table'
import { ColumnProps } from 'app/shared/table/types'
import { useSelector } from 'react-redux'
import { currentCart } from 'features/cart/cartSlice';
import { RowProps } from 'app/shared/table';
import Button from 'app/shared/button';
import LongArrowRightWhite from 'app/shared/assets/svgs/long-arrow-right-white.svg';
import LongArrowLeftAzure from 'app/shared/assets/svgs/long-arrow-left-azure.svg';
import { useDispatch } from 'react-redux';
import { remove } from 'features/cart/cartSlice';
import InputRadioGroup from 'shared/forms/input-radio-group';
import { Transfer } from './transfer';
import CreditCard from './credit-card';
import { Invoice } from './invoice';
import { PayPal } from './paypal';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { useGetCurrencyQuery } from 'services/graphql/settings'
import { CheckoutContext } from './checkout.provider'

const Methods = [
  {
    label: "Credit Card",
    value: "credit-card",
  },
  {
    label: "Transfer",
    value: "transfer"
  },
  {
    label: "PayPal",
    value: "paypal"
  },
  {
    label: "Invoice",
    value: "invoice"
  }
]

const headers: ColumnProps[] = [
  {
    label: 'Item'
  },
  {
    label: 'Price'
  }
]

type PriceCurrencyProps = {
  amount: number;
  currency: string;
}

const PriceCurrency = ({ 
  amount,
  currency
}: PriceCurrencyProps) => {
  return (
    <div className="flex-row d-flex align-items-end justify-content-end">
      <div className="bold-type font-size-14 line-height-140 me-1">{`$${new Intl.NumberFormat().format(amount)}`}</div>
      <div className='font-size-10 line-height-140 bold-type'>{currency}</div>
    </div>
  )
}

const Checkout = () => {
  const { goBack } = useHistory();
  const cart = useSelector(currentCart);
  const dispatch = useDispatch();
  const [method, setMethod] = useState<string>(Methods[0].value);
  const { data, isLoading, isSuccess } = useGetCurrencyQuery()
  const { pay } = useContext(CheckoutContext)

  const rows = cart.items.map<RowProps>((item, i) => (
    {
      key: i.toString(),
      values: [
        {
          value: item.name
        }, {
          value: <PriceCurrency amount={item.price} currency={item.currency} />
        }
      ]
    }
  ))

  const total = cart.items.reduce((acc, curr) => (acc + (curr.price * curr.qty)), 0)

  const handlePayNow = () => {
    // update the state
    pay()
  }

  return (
    <Tile 
      title="Start a conversation"
      subHeading="Confirm your order and payment details"
    >
      <form>
        <div className="row align-items-start">
          <div className="col">
            <div className="form-control-group flex-grow-1 me-3">
              <div className="form-control">
                <label>
                  <div className="bold">Your order</div>
                </label>
                <Table 
                  headers={headers} 
                  rows={rows}
                  emptyRowsContent={<div className="text-center">There are currently no items</div>}
                  onDelete={(key) => {
                    dispatch(remove(parseInt(key)))
                  }}
                  footer={
                    <div className="flex-row d-flex w-100 justify-content-end">
                      <span className="font-size-12 line-height-150 medium-type color-text me-4">Total</span>
                      <PriceCurrency amount={total} currency={data?.currency ?? 'CHF'} />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-control-group flex-grow-1 ms-2">
              <div className="form-control w-100">
                <label>
                  <div className="bold">Payment details</div>
                </label>
                <InputRadioGroup 
                  className="w-100"
                  name="payment_method"
                  value={'credit-card'}
                  onChange={(value) => {
                    setMethod(value);
                  }}
                  options={Methods} 
                />
              </div>
              {method === Methods[0].value && <CreditCard />}
              {method === Methods[1].value && <Transfer />}
              {method === Methods[2].value && <PayPal />}
              {method === Methods[3].value && <Invoice />}
            </div>
          </div>
        </div>
        <div className="flex-row p-4 mt-4 border-r-8 d-flex justify-content-between border-1">
          <Button variant="secondary" className="wide" onClick={() => {
            goBack();
          }}>
            <img src={LongArrowLeftAzure} alt="back" className='me-2'/>
            Back
          </Button>
          <Button variant="primary" className="wide" onClick={handlePayNow}>
            Pay Now
            <img src={LongArrowRightWhite} alt="next" className='ms-2' />
          </Button>
        </div>
      </form>
      
    </Tile>
  )
}

export default Checkout;