import React from "react"

interface Props {
  columnLength: number;
  content: React.ReactNode | React.ReactNode[] | null;
}

export const Footer = ({
  columnLength,
  content
}: Props) => {
  return (
    <tfoot>
      <tr>
        <td colSpan={columnLength}>{content}</td>
      </tr>
    </tfoot>
  )
}