import { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useGetOneTechnologyRequestQuery } from 'services/graphql/tech-requests'
import './assets/styles/view-technology-request.scss'
import Button from 'app/shared/button'
import IconLabel from 'app/shared/icon-label'
import MapPinGreenIcon from 'app/shared/assets/svgs/map-pin-green.svg';
import ThumbsUpIcon from 'app/shared/assets/svgs/thumbs-up-green.svg';
import RoundNStarIcon from 'app/shared/assets/svgs/rounded-n-star.svg';
import BoxesOddGreenIcon from 'app/shared/assets/svgs/boxes-odd-green.svg';
import PeopleGreenIcon from 'app/shared/assets/svgs/people-green.svg';
import BoxCascadeGreenIcon from 'app/shared/assets/svgs/box-cascade-green.svg';
import BoxTickGreenIcon from 'app/shared/assets/svgs/box-tick-green.svg';
import UnlockGreenIcon from 'app/shared/assets/svgs/unlock-green.svg';
import StarGreenOutlineIcon from 'app/shared/assets/svgs/star-green-outline.svg';
import { FavouriteWidget } from 'app/shared/favourite-widget'
import Pill from 'app/shared/pill'
import TickWhiteIcon from 'app/shared/assets/svgs/tick-white.svg';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { Countries } from 'shared/countries';
import SpannarGreenIcon from 'app/shared/assets/svgs/spannar-green.svg';
import CogGreenIcon from 'app/shared/assets/svgs/cog-green.svg';
import CubeGreenIcon from 'app/shared/assets/svgs/cube-green.svg';
import TextBubbleWhiteIcon from 'app/shared/assets/svgs/text-bubble-white.svg'
import Expand from 'app/shared/expand';
import { Image } from 'shared/image'
import { useAddViewCountMutation } from 'services/graphql/view-counts'
import { ItemRating } from 'app/shared'
import { useCredit } from 'hooks/index'
import { Enum_ConversationWithCategory } from 'features/cart/cartSlice'
import { Enum_Bookmark_Type } from 'generated/graphql'
import { ESGRatingOptions } from 'app-constants'
import { StartConversationButton } from 'app/shared/start-conversation-button/start-conversation-button'
import { trackEvent } from 'shared/mixpanel';

dayjs.extend(quarterOfYear)

const ViewTechnologyRequest = () => {
  const { push } = useHistory();
  const { id }: any = useParams()
  const { data, isLoading, isSuccess } = useGetOneTechnologyRequestQuery(id)
  const [ addCount ] = useAddViewCountMutation();
  const { deductCreditRedirect } = useCredit()

  useEffect(() => {
    if (isSuccess && data) {
      trackEvent({eventName: 'technology_request_detail', properties: {'id': id, 'name': data?.name}});
    }
  }, [isSuccess, data]);

  const imageUrl = (data?.hero_image !== undefined && data?.hero_image !== null) ? data?.hero_image.url: ''

  const getYearQuarter = (date: Date) => {
    const d = dayjs(date)
    return `Q${d.quarter()} ${d.year()}`
  }

  const countries = data?.country_implementation ? data?.country_implementation?.split(",") : [];

  const startConversationsWithAction = (action: Enum_ConversationWithCategory) => {
    deductCreditRedirect({
      action,
      path: '/app/startup/collaborate',
      targetId: id,
      targetType: 'corporate',
      name: data?.name ?? '',
    })
  }

  const startConversation = () => {
    startConversationsWithAction(Enum_ConversationWithCategory.EXPLORE)
  }

  const unlockBudget = () => {
    startConversationsWithAction(Enum_ConversationWithCategory.EXPLORE_UNLOCK_BUDGET)
  }

  useEffect(() => {
    addCount(`tech-req-${id}`)
  }, [])

  const KeyFile = (
    <div className="key-info">
      <h6>About them</h6>
      {data?.corporate_profile && 
        <IconLabel icon={MapPinGreenIcon}>
          {Countries.getName(data?.corporate_profile.country_jurisdiction || '', "en")}
        </IconLabel>}

      {data?.industries && data.industries.length > 0 && 
        <IconLabel icon={BoxesOddGreenIcon}>
          {data.industries.map((item) => item !== null ? item.name: '').join(" / ")}
        </IconLabel>}

      {data?.corporate_profile && 
        <IconLabel icon={PeopleGreenIcon}>
          {data?.corporate_profile.employee_count_range}
        </IconLabel>}
      
      {data?.corporate_profile?.esg_rating ? 
        <IconLabel icon={StarGreenOutlineIcon}>
          ESG Rate - {ESGRatingOptions.find(({value}) => value === data?.corporate_profile?.esg_rating)?.label ?? ''}
        </IconLabel>
      : null}

      {data?.corporate_departments && data.corporate_departments.length > 0 && 
        <IconLabel icon={BoxCascadeGreenIcon}>
          {data.corporate_departments.map((item) => item !== null ? item.name: '').join(" / ")
        }</IconLabel>}
      
      <h6 className="mt-4">About the project</h6>
      
      <div className="list">
        {data?.projected_startdate !== null &&
        <div className="item">
          <label>Kick off date</label>
          <span>{getYearQuarter(data?.projected_startdate)}</span>
        </div>
        }
        {data?.projected_complete_date !== null &&
        <div className="item">
          <label>Delivery date</label>
          <span>{getYearQuarter(data?.projected_complete_date)}</span>
        </div>
        }
        <div className="item">
          <label>Budget</label>
          <span>
            <Button variant="link" className="flex-row color-java d-flex align-items-center" onClick={unlockBudget}>
              <div className="mb-1 icon">
                <img src={UnlockGreenIcon} className="me-1" alt="unlock" />
              </div>
              <span className="medium-type text-decoration-underline text-lowercase">unlock Budget</span>
            </Button>
          </span>
        </div>
        {countries.length > 0 && 
        <div className="item">
          <label>Implementation</label>
          <span className="flex-column d-flex">
            {countries.map((item, i) => (<span key={i} className="mb-0 me-2">{Countries.getName(item, "en")}</span>))}
          </span>
        </div>}
      </div>

      {data?.corporate_profile && data?.corporate_profile.worked_with_startups &&
        <IconLabel className="mt-5" icon={BoxTickGreenIcon}>
          Has worked with startups
        </IconLabel>
      }

    </div>
  )
  
  return (
    <div className="view-technology-request">
      <div className="left">
        <div className="image">
          <div className="top-right">
            <FavouriteWidget 
              itemId={id} 
              type={Enum_Bookmark_Type.TechnologyRequest}
            />
          </div>
          <Image src={imageUrl} alt="" />
        </div>          
        {false && <div className="recommended">
          <div className="body">
            <img src={ThumbsUpIcon} alt="" />
            Recommended by startups
          </div>
          <img className="back" src={RoundNStarIcon} alt="" />
        </div>}
        {KeyFile}
      </div>
      <div className="right">
        <div className="container-fluid p-0">
          <h2 className="mb-3">{data?.name || ''}</h2>
          
          <div className="mb-3">
            <ItemRating itemId={`corp-${data?.corporate_profile?.id}`} />
          </div>
          
          <h5 className="pt-3">Business need description</h5>
          <p className="mt-3 mb-4">{data?.business_challange_description || ''}</p>

          <div className="pt-4 row">
            <div className="col-12 col-md-6 align-items-start">
              <h5>Looking to</h5>
              <div className="flex-row flex-wrap pt-2 d-flex">
                {data?.want_poc && <Pill label="POC" icon={TickWhiteIcon} />}
                {data?.want_seller && <Pill label="Sell" icon={TickWhiteIcon} />}
                {data?.want_lease && <Pill label="Lease" icon={TickWhiteIcon} />}
                {data?.want_investment && <Pill label="Invest" icon={TickWhiteIcon} />}
              </div>
            </div>
            <div className="col-12 col-md-6 align-items-start">
              <h5>Interested in</h5>
              <div className="flex-row flex-wrap pt-2 d-flex">
                {data?.is_cloud && <Pill label="Cloud" icon={TickWhiteIcon} />}
                {data?.sdg_driven && <Pill label="SDG Driven" icon={TickWhiteIcon} />}
                {data?.is_gdpr && <Pill label="GDPR" icon={TickWhiteIcon} />}
                {data?.is_plugandplay && <Pill label="Plug & Play" icon={TickWhiteIcon} />}
                {data?.has_api && <Pill label="API" icon={TickWhiteIcon} />}
                {data?.has_ip && <Pill label="IP" icon={TickWhiteIcon} />}
                {data?.is_spin_off && <Pill label="Spin-off/Tested" icon={TickWhiteIcon} />}
                {data?.can_whitelabel && <Pill label="White label available" icon={TickWhiteIcon} />}
              </div>
            </div>
          </div>

          <div className="mt-5 row">
            <div className="col-12 col-md-6 align-items-start">
              <h5>Business Objectives</h5>
              <div className="flex-row flex-wrap pt-2 d-flex">
                {data?.goals?.map((item, i) => (item !== null && <Pill variant="show-all" icon={TickWhiteIcon} key={i} label={item?.Name || ''} />))}
              </div>
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0 align-items-start">
              <h5>Impact for their organization</h5>
              <div className="flex-row flex-wrap pt-2 d-flex">
                {data?.impact_level && <Pill variant="show-all" label={data?.impact_level} icon={TickWhiteIcon} />}
                {data?.impact_area?.name && <Pill variant="show-all" label={data?.impact_area?.name} icon={TickWhiteIcon} />}
              </div>
            </div>
          </div>

          <div className="mt-5 row">
            <div className="col-12 col-md-6">
              <Expand icon={SpannarGreenIcon} label="Use Cases /Application">
                <>
                  {data?.business_use_cases?.map((item, i) => (item !== null && <Pill variant="show-all" key={i} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
            <div className="col-12 col-md-6">
              <Expand icon={CogGreenIcon} label="Interested technology">
                <>
                  {data?.technology_types?.map((item, i) => (item !== null && <Pill variant="show-all" key={i} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
          </div>

          <div className="mt-0 mt-md-3 row">
            <div className="col-12">
              <Expand icon={CubeGreenIcon} label="SDG Objectives">
                <>
                  {data?.sdg_objectives?.map((item, i) => (item !== null && <Pill variant="show-all" key={`sdg-${i}`} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
          </div>

          {KeyFile}

          <StartConversationButton onStartConversation={startConversation} />

        </div>
      </div>
    </div>
  )
}

export default ViewTechnologyRequest;