import { RowProps } from './types';
import { Cell } from './cell';
import { CellDelete } from './cell.delete';

interface Props extends RowProps {
  showRowDelete: boolean;
  onDelete: (key: string) => unknown;
}

export const Row = ({
  key = '',
  values,
  showRowDelete,
  onDelete,
}: Props) => {
  return (
    <tr>
      {showRowDelete && <CellDelete key={key} onDelete={(key: string) => {
        onDelete(key)
      }} />}
      {values.map((item, i) => <Cell key={i} value={item.value} />)}
    </tr>
  );
}