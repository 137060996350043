import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import modalReducer from './features/modals/modalSlice'
import authReducer from './features/auth/authSlice'
import signupReducer from './features/signup/signupSlice'
import headerProfileReducer from 'features/header-profile/headerProfileSlice'
import inProgressReducer, { InProgressState } from 'features/in-progress/inProgressSlice'
import { modalStateType } from './features/modals/modalSlice'
import { AuthState } from './features/auth/authSlice'
import { authApi } from './services/graphql/auth'
import { onBoardingSessionApi } from 'services/graphql/on-boarding-session'
import { techSolutionsApi } from 'services/graphql/tech-solutions'
import { techRequestsApi } from 'services/graphql/tech-requests'
import { bookmarksApi } from 'services/graphql/bookmarks'
import { matchMakingResultsApi } from 'services/graphql/match-making-result'
import { newsApi } from 'services/graphql/news'
import { tagsApi } from 'services/graphql/tags'
import { uploadApi } from 'services/graphql/upload'
import { conversationApi } from 'services/graphql/conversation'
import { profileApi } from 'services/graphql/profile'
import { catalystAllocationApi } from 'services/graphql/catalyst-allocation'
import { documentSharingApi } from 'services/graphql/document-sharing'
import { viewCountApi } from 'services/graphql/view-counts'
import { ratingsApi } from 'services/graphql/ratings'
import { singleTypesApi } from 'services/graphql/single-types'
import { enquiriesApi } from 'services/graphql/enquiries'
import { creditsApi } from 'services/graphql/credits'
import { servicesApi } from 'services/graphql/services'
import { servicesInterestApi } from 'services/graphql/services-interest'
import { settingsApi } from 'services/graphql/settings'
import { notificationsApi } from 'services/graphql/notifications'
import { servicePackagesApi } from 'services/graphql/service-packages'
import { purchasedSubscriptionApi } from 'services/graphql/purchased-subscription'
import { catalystCoversationsApi } from 'services/graphql/catalyst-conversations'
import { serviceCollaborationApi } from 'services/graphql/service-collaboration'
import { companyApi } from 'services/graphql/company'
import { messagingApi } from 'services/graphql/chat'
import { jobsApi } from 'services/graphql/jobs'
import { paymentsApi } from 'services/graphql/payments'
import { promoCodeConversationApi } from 'services/graphql/promo-code-conversation'

import toastsReducer, { toastStateType } from './features/toasts/toastsSlice'
import cartReducer, { CartStateType } from './features/cart/cartSlice'
import appConfigReducer, { appConfigStateType } from './features/app-config/appConfigSlice'
import chatsReducer, { ChatStateType } from './features/chat/chatSlice'

export interface appReducerStateType {
  modal: modalStateType,
  auth: AuthState,
  toasts: toastStateType,
  cart: CartStateType,
  inProgress: InProgressState,
}

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [onBoardingSessionApi.reducerPath]: onBoardingSessionApi.reducer,
    [techSolutionsApi.reducerPath]: techSolutionsApi.reducer,
    [techRequestsApi.reducerPath]: techRequestsApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [bookmarksApi.reducerPath]: bookmarksApi.reducer,
    [matchMakingResultsApi.reducerPath]: matchMakingResultsApi.reducer,
    [conversationApi.reducerPath]: conversationApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [onBoardingSessionApi.reducerPath]: onBoardingSessionApi.reducer,
    [catalystAllocationApi.reducerPath]: catalystAllocationApi.reducer,
    [documentSharingApi.reducerPath]: documentSharingApi.reducer,
    [viewCountApi.reducerPath]: viewCountApi.reducer,
    [ratingsApi.reducerPath]: ratingsApi.reducer,
    [singleTypesApi.reducerPath]: singleTypesApi.reducer,
    [enquiriesApi.reducerPath]: enquiriesApi.reducer,
    [creditsApi.reducerPath]: creditsApi.reducer,
    [servicesApi.reducerPath]: servicesApi.reducer,
    [servicesInterestApi.reducerPath]: servicesInterestApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [servicePackagesApi.reducerPath]: servicePackagesApi.reducer,
    [purchasedSubscriptionApi.reducerPath]: purchasedSubscriptionApi.reducer,
    [catalystCoversationsApi.reducerPath]: catalystCoversationsApi.reducer,
    [serviceCollaborationApi.reducerPath]: serviceCollaborationApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [messagingApi.reducerPath]: messagingApi.reducer,
    [jobsApi.reducerPath]: jobsApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [promoCodeConversationApi.reducerPath]: promoCodeConversationApi.reducer,
    auth: authReducer,
    signup: signupReducer,
    modal: modalReducer,
    toasts: toastsReducer,
    headerProfile: headerProfileReducer,
    inProgress: inProgressReducer,
    cart: cartReducer,
    appConfig: appConfigReducer,
    chats: chatsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(
        authApi.middleware,
        onBoardingSessionApi.middleware,
        techSolutionsApi.middleware,
        techRequestsApi.middleware,
        bookmarksApi.middleware,
        newsApi.middleware,
        tagsApi.middleware,
        uploadApi.middleware,
        matchMakingResultsApi.middleware,
        conversationApi.middleware,
        profileApi.middleware,
        onBoardingSessionApi.middleware,
        catalystAllocationApi.middleware,
        documentSharingApi.middleware,
        viewCountApi.middleware,
        ratingsApi.middleware,
        singleTypesApi.middleware,
        enquiriesApi.middleware,
        creditsApi.middleware,
        servicesApi.middleware,
        servicesInterestApi.middleware,
        settingsApi.middleware,
        notificationsApi.middleware,
        servicePackagesApi.middleware,
        purchasedSubscriptionApi.middleware,
        catalystCoversationsApi.middleware,
        serviceCollaborationApi.middleware,
        companyApi.middleware,
        messagingApi.middleware,
        jobsApi.middleware,
        paymentsApi.middleware,
        promoCodeConversationApi.middleware
      ),
})

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch