import mixpanel from 'mixpanel-browser';

function initialize() {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
  debug: true,
  track_pageview: true,
    persistence: "localStorage",
  });
}

type SetUserPropertiesInput = {
  id: string,
  properties: Record<string, any>
}

function setUserProperties({id, properties}: SetUserPropertiesInput) {
  mixpanel.identify(id);
  mixpanel.people.set({
    $type: properties['type'],
  });
}

type trackEventInput = {
  eventName: string
  properties?: Record<string, any>
}

function trackEvent({eventName, properties}: trackEventInput) {
  mixpanel.track(eventName, properties);
}

export {
  initialize,
  setUserProperties,
  trackEvent
}