import { useState, useEffect } from 'react';
import './assets/styles/login.scss';
import InputPassword from 'shared/forms/input-password';
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from 'features/modals/modalSlice'
import { selectCurrentUser } from 'features/auth/authSlice'
import { useForm, SubmitHandler } from 'react-hook-form'
import {
  useHistory,
  useLocation
} from 'react-router-dom'
import { useLoginMutation, useLazyMeQuery } from 'services/graphql/auth'
import { UsersPermissionsLoginPayload } from 'generated/graphql'
import Session from 'session'

import { setUserProperties } from 'shared/mixpanel';

interface Inputs {
  email: string;
  password: string;
}

const Login = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { state } = useLocation();
  const [error, setError] = useState(false);
  const [ login, { isLoading } ] = useLoginMutation();
  const [ getMe ] = useLazyMeQuery();

  const currentUser = useSelector(selectCurrentUser);
  
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
    mode: "onChange"
  });
  
  const onSubmit: SubmitHandler<Inputs> = async (loginData: Inputs) => {
    try {
      let { jwt, user }: UsersPermissionsLoginPayload  = await login({
        identifier: loginData.email,
        password: loginData.password
      }).unwrap();
      jwt && Session.set(jwt)
      setUserProperties({id: user?.id, properties: {
        'type': user?.role?.type
      }});
      push(`/app/${user?.role?.type}/dashboard`)
      
      dispatch(close());
      
    } catch (err) {
      setError(true)
    }
  }
  
  const handleSignUpClick = () => {
    // change modal to sign up  
    dispatch(open('sign-up'))
  }
  
  const handleForgetPasswordClick = () => {
    // change modal to forget password
    push('/forgot-password');
    // dispatch(open('forgot-password'))
  }

  useEffect(() => {
    if (currentUser !== undefined && currentUser?.role !== undefined && currentUser.role?.type !== undefined) {
      push(`/app/${currentUser.role.type}`)
      /*
      const routeState = state as any;
      if (routeState) {
        push(routeState.from.pathname);
      } else {
        push(`/app/${currentUser.role.type}`)
      }
      */
    } else {
      getMe();
    }
  }, [currentUser]);
  
  return (
    <div className="login">
      <h1 className="mb-5">Log in</h1>
      <form className="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {isLoading && <div>Loading</div>}
        {error && <div className="alert alert-danger" role="alert">
          Your credentials are invalid
        </div>}
        <label id="email-address-txt">
          <div className="text">
            Email Address
          </div>
          <input type="text" {...register("email", { required: true })} autoComplete="off" />
          <div className={`invalid-feedback ${errors.email ? 'd-block':''}`}>This field is required</div>
        </label>
        <label id="password-txt">
          <div className="text">
            Password
          </div>
          <InputPassword {...register("password", { required: true})} autoComplete="off" />
          <div className={`invalid-feedback ${errors.password ? 'd-block':''}`}>This field is required</div>
        </label>
        <div className="flex-row-reverse d-flex">
          <button type="button" className="mt-1 mb-4 btn-link txt-uppercase" onClick={handleForgetPasswordClick} >Forgot Password</button>
        </div>
        <button type="submit" className="btn btn-primary" >Log In</button>
      </form>
      <div className="mt-5 text-center txt-weight-500">
        No account yet? <button type="button" className="btn-link" onClick={handleSignUpClick} >Sign up now</button>
      </div>
    </div>
  )
}

export default Login;