import { useEffect } from 'react';
import { selectCurrentUser } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams
} from 'react-router-dom';
import StartupExplore from 'app/startup/explore'
import ViewTechnologyRequest from 'app/startup/explore/view-technology-request';
import CorporateExplore from 'app/corporate/explore'
import ViewTechnologySolution from 'app/corporate/explore/view-technology-solution';
import { trackEvent } from 'shared/mixpanel';

const Explore = () => {
  const user = useSelector(selectCurrentUser)
  let { path } = useRouteMatch();

  useEffect(() => {
    trackEvent({eventName: 'explore', properties: {'type': user?.role?.type}});
  }, []);
  
  return (
    <Switch>
      <Route exact path={path}>
        {user !== null && user.role !== null && user.role?.type === "startup" && <StartupExplore />}
        {user !== null && user.role !== null && user.role?.type === "corporate" && <CorporateExplore />}
      </Route>
      <Route path={`${path}/:id`}>
        {user !== null && user.role !== null && user.role?.type === "startup" && <ViewTechnologyRequest />}
        {user !== null && user.role !== null && user.role?.type === "corporate" && <ViewTechnologySolution />}
      </Route>
    </Switch>
    
  );
};

export default Explore;