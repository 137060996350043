import { CountryNames } from 'shared/countries'
import SelectOne from 'shared/forms/select-one'
import './assets/styles/transfer.scss'

export const Transfer = () => {
  return (
    <div className="transfer">
      <div className="mb-4 font-size-14 txt-semibold line-height-100">My Corporate Bank Details</div>
      <div className="form-control">
        <label>
          <div className="text">Account Number</div>
          <input type="text"/>
        </label>
      </div>
      <div className="form-control">
        <label>
          <div className="text">Bank Name</div>
          <input type="text"/>
        </label>
      </div>
      <div className="form-control">
        <label>
          <div className="text">Bank Country</div>
          <SelectOne options={CountryNames} />
        </label>
      </div>
      <div className="form-control">
        <label>
          <div className="text">Bank Code</div>
          <input type="text"/>
        </label>
      </div>
      <div className="form-control">
        <label>
          <div className="text">IBAN</div>
          <input type="text"/>
        </label>
      </div>
      <div className="form-control">
        <label>
          <div className="text">Swiftcode</div>
          <input type="text"/>
        </label>
      </div>
      <div className="bank-details">
        <div className="mb-4 font-size-14 txt-semibold line-height-100">ALKELIO’s Bank Details </div>
        <div className="row">
          <div className="col-3 field">Bank</div>
          <div className="col-9 value">ING Belgium NV, Avenue Marnix 24, <br/>1000 Brussels, Belgium</div>
        </div>
        <div className="row">
          <div className="col-3 field">IBAN Code</div>
          <div className="col-9 value">BE93 3631 8789 1767</div>
        </div>
        <div className="row">
          <div className="col-3 field">BIC/SWIFT code</div>
          <div className="col-9 value">BBRUBEBB</div>
        </div>
        <div className="flex-row row d-flex align-items-start">
          <div className="col-3 field">Payment Reference</div>
          <div className="col-9 value">
            10101416<br/>
            <div className="mt-3 txt-light font-size-12 line-height-140">This number must be the only message to ECHA in your payment (the no. here is an example)</div>
          </div>
        </div>
      </div>
    </div>
  )
}