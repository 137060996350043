import { FileItem } from 'hooks/file-uploads';
import { Enum_Personprofile_Profile_Type } from 'generated/graphql';

export enum SignUpTypes {
  STARTUP = 'startup',
  CORPORATE = 'corporate'
}

export type SignUpType = SignUpTypes.STARTUP | SignUpTypes.CORPORATE | null

export type StartupProfileFormInputs = {
  name?: string;
  country?: string;
  industries?: string[];
  start_date?: string;
  client_count?: string;
  employee_count: number;
  logo: FileItem,
  operating_locations: string[];
  maturity_level: string;
  went_incubator_accelerator: string;
  investment_received: string;
  investment_received_currency: string;
  esg_rating: string;
}

export type StartupPersonProfileUserInputs = {
  first_name: string;
  last_name: string;
  contact_number: string;
  photo: FileItem;
  role: string;
  email: string;
}

export interface StartupTechSolutionInputs {
  name: string;
  description: string;
  has_api: boolean;
  has_ip: boolean;
  is_gdpr: boolean; 
  is_whitelabel: boolean;
  is_plugandplay: boolean;
  is_cloud: boolean;
  sdg_driven: boolean;
  is_spin_off: boolean;
  spin_off_source: string;
  technology_readiness: string;
  technology_types: Array<string>;
  business_use_cases: Array<string>;
  industries: Array<string>;
  corporate_departments: Array<string>;
  technology_solution_pricing_model: Array<{
    currency: string;
    min_budget: number;
    option: string;
    pricing_model: string;
  }>;
  sdg_objectives: Array<string>
}

export interface MyCompanyInputs {
  name: string;
  country_jurisdiction: string;
  industries: Array<string>;
  employee_count_range: string;
  logo: FileItem;
  country: Array<string>;
  esg_rating: string;
}

export interface CorpPersonProfileInputs {
  first_name: string;
  last_name: string;
  corporate_department: string;
  contact_number: string;
  photo: FileItem;
  role: string;
  is_decisionmaker: boolean;
  is_decision_influencer: boolean;
  decision_role_type: string;
  profile_type: Enum_Personprofile_Profile_Type;
  email: string
}

export interface BusinessChallengeInputs {
  name: string;
  description_project: string;
  business_challange_description: string;
  goals: Array<string>;
  has_api: boolean;
  has_ip: boolean;
  is_gdpr: boolean;
  is_spin_off: boolean;
  can_whitelabel: boolean;
  is_plugandplay: boolean;
  is_cloud: boolean;
  sdg_driven: boolean;
  is_others: string;
  technology_solution_model_b2b: boolean;
  technology_solution_model_b2c: boolean;
  technology_solution_model_b2b2c: boolean;
  technology_solution_model_others: string;
  impact_level: string;
  impact_area: string;
  business_use_cases: Array<string>;
  technology_types: Array<string>;

  project_budget: Array<{
      currency: string;
      budget: number;
      option: string;
  }>;

  sdg_objectives: Array<string>
}