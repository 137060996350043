import CircleOCross from 'app/shared/assets/svgs/circle-o-cross.svg';
import './assets/styles/cell.delete.scss';

interface Props {
  key: string;
  onDelete: (key: string) => unknown
}

export const CellDelete = ({
  onDelete,
  key
}: Props) => {
  return (
    <td className="cell-delete" onClick={() => {
      onDelete(key);
    }}>
      <img src={CircleOCross} alt="remove" />
    </td>
  )
}