import React from 'react';
import { useParams } from 'react-router-dom';
import '../assets/styles/profile-page.scss';
import Tile from 'app/shared/tile';
import XWhiteIcon from "app/shared/assets/svgs/x-white.svg";
import InputCheckBox from "shared/forms/input-checkbox";
import InputImage from "shared/forms/gray-image";
import Pill from "app/shared/pill";
import BoxTick from "app/shared/assets/svgs/BoxCheckBlue.svg";
import BoxCheck from "app/shared/assets/svgs/BoxXBlue.svg";
import Grid from '@material-ui/core/Grid';
import { Image } from 'shared/image';
import { Box } from '@material-ui/core';
import { useGetConversationCorporateProfileQuery } from 'services/graphql/conversation';
import { Countries } from 'shared/countries';
import { trackEvent } from 'shared/mixpanel';

const CorProfile = () => {

    const { id }: any = useParams()
    const { data, isLoading, isSuccess } = useGetConversationCorporateProfileQuery(id);
    React.useEffect(() => {
        console.log("Conv ID", id);
        console.log("Corp Profile:", data);
    }, [data, id]);

    React.useEffect(() => {
        trackEvent({eventName: 'conversation_corporate_profile_view', properties: {id: id}});
    }, []);

    return (
        <div className="container-fluid">

            {/* <div className="col"> */}
            <Tile>
                <h3> {data?.name}</h3>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="stretch"
                    className=""
                >
                    <Grid item md={4} style={{padding:'16px'}}>
                        <Box
                            border={1}
                            borderColor="grey.300"
                            borderRadius={8}
                            className="sideBySideProfile"
                        >
                            <div className="whiteBox">
                                {/* <div className="partnerBG"> */}

                                <img src={data?.logo?.url || "" } alt="Partner" />

                                {/* </div> */}
                            </div>
                        </Box>
                        <Box
                            border={1}
                            borderColor="grey.300"
                            className="grayBoard"
                            borderRadius={8}
                        >
                            <h6>Website</h6>
                            <div className="subBody" >
                                {/* {data?.links?.map((item)=>{
                                    return <span> {item?.website} </span>
                                })} */}
                                <span>{data?.links?.website}</span>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item md={8} style={{padding:'16px'}}>
                        <Box
                            border={1}
                            borderColor="grey.300"

                            borderRadius={8}
                            className="proBoard"
                        >
                            <div className="section">
                                <h6>Summary</h6>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Industries</b>
                                        </label>
                                        <div className="chip">
                                                {data?.industries?.map((item, index) => {
                                                    return <Pill iconPosition='left' variant="show-all" key={index} label={item?.name || 'Label'} />
                                                })}
                                            {/* <Pill label={"Any label"} icon={XWhiteIcon} /> */}
                                        </div>

                                    </Grid>

                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Operating Locations</b>
                                        </label>

                                        {data?.country ? <div className="chip">
                                            {data?.country.split(',').map(country => (
                                                <Pill iconPosition='left' variant="show-all" label={Countries.getName(country || '', "en")} />
                                            ))}
                                            
                                        </div>: <p><div className="subBody">Not Available</div></p>}
                                    </Grid>
                                    
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Country (Jurisdiction)</b>
                                        </label>

                                        {/*@todo Add pricing */}
                                        <div className="subBody">{Countries.getName(data?.country_jurisdiction || '', "en")}</div>
                                    </Grid>
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Employee Count Range</b>
                                        </label>
                                        {/*@todo Add Contract duration */}
                                        <div className="subBody">{data?.employee_count_range}</div>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>

                                        <div >
                                            {(data?.worked_with_startups) ? <div>
                                                
                                                <label className='subBody'> <img src={BoxTick} style={{paddingRight:'8px'}} alt={"Yes"} />
                                                Has worked with Startups previously</label>
                                                </div>
                                                : <div>
                                                <label className='subBody'><img src={BoxCheck} style={{paddingRight:'8px'}} alt={"No"} />Has not worked with Startups previously</label>
                                                </div>}
                                            
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>

                                    </Grid>
                                </Grid>

                            </div>

                        </Box>
                        <Box
                            border={1}
                            borderColor="grey.300"

                            borderRadius={8}
                            className="proBoard"
                        >
                            <div className="section">
                                <h6>Personal Profile</h6>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        <div className="represtative">
                                        {data?.person_profiles?.map((item, index) => {
                                            return <Image
                                                
                                            style={{
                                                marginTop: "8px",
                                                marginBottom: "8px",
                                                width: "80px",
                                                height: "80px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                            }}
                                            src={item?.photo?.url}
                                        />
                                        })}
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>

                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">First Name</b>
                                        </label>

                                        {data?.person_profiles?.map((item, index) => {
                                            return (<div key={index} className="subBody">
                                                {item?.first_name || 'Not Available'}
                                            </div>);
                                        })}
                                    </Grid>
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Last Name</b>
                                        </label>
                                        {data?.person_profiles?.map((item, index) => {
                                            return (<div key={index} className="subBody">
                                                {item?.last_name || 'Not Available'}
                                            </div>);
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Email</b>
                                        </label>

                                        <div className="subBody">
                                            {data?.person_profiles?.map((item, index) => {
                                                return (<span key={index} >
                                                    {item?.user?.email || 'Not Available'}
                                                </span>);
                                            })}
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>

                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Role</b>
                                        </label>

                                        {data?.person_profiles?.map((item, index) => {
                                            return (<div key={index} className="subBody">
                                                {item?.role || 'Not Available'}
                                            </div>);
                                        })}
                                    </Grid>
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Department</b>
                                        </label>

                                        {data?.person_profiles?.map((item, index) => {
                                            return (<div key={index} className="subBody">
                                                {item?.corporate_department?.name || 'Not Available'}
                                            </div>);
                                        })}

                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        {data?.person_profiles?.map((item, index) => {

                                            return (<div key={index}>{(item?.is_decisionmaker)? <div>
                                                
                                                <label className='subBody'><img src={BoxTick} style={{ paddingRight: '8px' }} alt={"Yes"} />I am the decision maker</label>
                                            </div> : <div>
                                                <label className='subBody'><img src={BoxCheck} style={{ paddingRight: '8px' }} alt={"No"} />I am not the decision maker</label>
                                            </div>}</div>);
                                        })}


                                    </Grid>
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Contact Number</b>
                                        </label>
                                        {data?.person_profiles?.map((item, index) => {
                                            return (<div key={index} className="subBody">
                                                {item?.contact_number || 'Not Available'}
                                            </div>);
                                        })}

                                    </Grid>
                                </Grid>
                            </div>

                        </Box>
                    </Grid>
                </Grid>
            </Tile>
            {/* </div> */}
        </div>
    )
}

export default CorProfile;