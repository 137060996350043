import { useEffect } from 'react';
import './assets/styles/collaboration.scss'
import {
  Switch,
  Route,
  useRouteMatch,
  useParams
} from 'react-router-dom';
import Conversation from './conversation';
import CorProfile from './conversation/corProfile';
import StartuProfile from './conversation/startuProfile';
import ConversationCard from './conversation-card'
import Conversations from './conversations';
import MobileMessage from './mobile-message';

import { selectCurrentUser } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useGetMyConversationsQuery, ConversationType } from 'services/graphql/conversation'

import Tile from 'app/shared/tile'
import GenericModal from 'shared/generic-modal';
import MutualNDA from './mutual-nda';


const Collaborate = () => {
  const { entityType }: any = useParams();
  let { path } = useRouteMatch();

  const isMobileWidth = window.innerWidth < 768

  // show message for mobile - This space is currently not optimized for mobile. We are working on it!

  return (
    <Switch>
      <Route exact path={path}>
        {isMobileWidth ? 
        <MobileMessage/>
        : 
        <>
          <Tile 
            title="Conversations" >
            <Conversations/>
          </Tile>
          <GenericModal modalId="conversation-mutual-nda" size="md" title="Mutual Non-Disclosure Agreement">
            <MutualNDA />
          </GenericModal>
        </>
        }
      </Route>

      <Route path={`${path}/:id/startup-profile`}>
        <StartuProfile />
      </Route>
      
      <Route path={`${path}/:id/corp-profile`}>
        <CorProfile />
      </Route>
      
      <Route path={`${path}/:id`}>
        <Conversation />
      </Route>
      
    </Switch>
  )
};

export default Collaborate;