import React from 'react'
import { selectCurrentUser } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams
} from 'react-router-dom';
import TechSolutionMatches from 'app/startup/matches';
import TechnologySolutionMatchDetail from 'app/startup/matches/view-match';
import TechRequestMatches from 'app/corporate/matches';
import TechnologyRequestMatchDetail from 'app/corporate/matches/view-match';
import TechSolutionForm from '../startup/tech-solution-form';
import TechRequestForm from '../corporate/tech-request-form';
import EntityConstants from 'app/entity-type/entityConstants';
import { trackEvent } from 'shared/mixpanel';

const Matches = () => {
  const user = useSelector(selectCurrentUser)
  let { path } = useRouteMatch();
  const { entityType }: any = useParams();
  
  React.useEffect(() => {
    trackEvent({eventName: 'explore', properties: {'type': entityType}});
  }, []);

  return (
    <Switch>
      <Route exact path={path}>
        {user !== null && user.role !== null && user.role?.type === "startup" && <TechSolutionMatches />}
        {user !== null && user.role !== null && user.role?.type === "corporate" && <TechRequestMatches />}
      </Route>
      <Route exact path={`${path}/:id`}>
        {user !== null && user.role !== null && user.role?.type === "startup" && <TechnologySolutionMatchDetail />}
        {user !== null && user.role !== null && user.role?.type === "corporate" && <TechnologyRequestMatchDetail />}
      </Route>
      {entityType === EntityConstants.STARTUP &&
      <Route exact path={`${path}/tech-solutions/new`}>
        <TechSolutionForm/>
      </Route>}
      {entityType === EntityConstants.STARTUP &&
      <Route path={`${path}/tech-solutions/:id`}>
        <TechSolutionForm/>
      </Route>}
      {entityType === EntityConstants.CORP &&
      <Route exact path={`${path}/tech-requests/new`}>
        <TechRequestForm/>
      </Route>}
      {entityType === EntityConstants.CORP &&
      <Route path={`${path}/tech-requests/:id`}>
        <TechRequestForm/>
      </Route>}
    </Switch>
  )
}

export default Matches;