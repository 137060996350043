import React, { useEffect } from 'react'
import Button from 'app/shared/button'
import TextBubbleWhiteIcon from 'app/shared/assets/svgs/text-bubble-white.svg'
import { useUsePromoCodeStartConversationMutation } from 'services/graphql/promo-code-conversation';
import { useHistory, useParams } from "react-router-dom"
import { showToast } from "features/toasts/toastsSlice";
import { useDispatch } from "react-redux";
import { RedeemStartConversationInput } from 'generated/graphql';
import { useLazyGetMyConversationsQuery } from 'services/graphql/conversation'
import { trackEvent } from 'shared/mixpanel';

interface Props {
  onStartConversation: () => void
}

export const StartConversationButton = (
  props: Props, 
  children: React.ReactNode
) => {
  const dispatch = useDispatch();
  const [ claimCode, { data, isLoading, isSuccess } ] = useUsePromoCodeStartConversationMutation();
  const [getConversations, fetchState] = useLazyGetMyConversationsQuery()
  const codeRef = React.useRef<HTMLInputElement>(null);
  const { push } = useHistory();
  const { id, entityType }: any = useParams()

  const onClick = async () => {
    trackEvent({eventName: 'conversation_start', properties: {'id': id }});

    if (codeRef.current) {
      // redeem code and start conversation
      const payload: Record<string, unknown> = {
        'code': codeRef.current.value
      }

      if (entityType === 'startup') {
        payload['techRequestId'] = id;
      } else
      if (entityType === 'corporate') {
        payload['techSolutionId'] = id;
      }

      const claimResult = await (await claimCode(payload as RedeemStartConversationInput).unwrap());
      if (claimResult.result) {
        //redirect to conversations page
        await (await getConversations().unwrap());
        push(`/app/${entityType}/collaborate`);
      } else {
        // TODO show toast code invalid
        dispatch(
          showToast({
            title: "Redeem Code",
            body: "Code is invalid",
            time: null,
          })
        );
    }
    } else {
      props.onStartConversation();
    }
  }

  return (
    <div className="flex-column mt-5 border start-conversation">
      <div className="code d-flex flex-row mb-3 align-items-center">
        <div className="d-flex flex-row">
          <label className="d-flex flow-row">
            <div className="text font-size-14 mt-2 line-height-160 mr-2">Enter Conversation Code</div>
            <input type="text" className="input-text-compact" ref={codeRef} />
          </label>
        </div>
      </div>
      <div className="d-flex flex-md-row flex-column">
        <h6>Want to know more or connect?</h6>
        <Button variant="primary" onClick={onClick}> 
          <img src={TextBubbleWhiteIcon} className="me-2" alt="start conversation" />
          Start Conversation
        </Button>
      </div>
    </div>
  );
}