import { useEffect, useState } from 'react';
import FormLayout from './form-layout';
import ActionBar from '../action-bar/action-bar';
import { useForm, Controller, UseFormWatch, useWatch, Control, UseFormRegister } from 'react-hook-form';
import './assets/styles/form-layout-form.scss';

import {
  useGetCorporateDepartmentsQuery,
  useGetBusinessUsecasesQuery,
  useGetIndustriesQuery,
  useGetTechnologyTypesQuery,
  useGetImpactAreasQuery,
  useGetGoalsQuery,
  useGetSdgObjectivesQuery
} from 'services/graphql/tags';

import {
  BusinessUseCase,
  EditTechnologyRequestInput,
  Industry,
  TechnologyType,
  ImpactArea,
  OnboardingSessionCorporate_TechnologyRequest,
  SdgObjectives
} from 'generated/graphql';

import { toOptions, toValues, unNull, useWindowDimensions } from 'shared/utilities'

import { ImpactLevels } from 'app/constants';

import useOnboarding from '../hooks/onboarding.hook'
import useSteps from '../hooks/steps.hook'

import InputInline from 'shared/forms/input-inline';
import StatusBadge from 'app/shared/status-badge';
import InputImage from 'shared/forms/input-image';
import InputFiles from 'shared/forms/input-files';
import InputCheckBox from 'shared/forms/input-checkbox';
import SelectMultiple, { OptionItem } from 'shared/forms/select-multiple';
import SelectOne from 'shared/forms/select-one';
import NeedHelp from 'app/startup/tech-solution-form/need-help';
import Certify from 'app/startup/tech-solution-form/certify';

import { BusinessChallengeInputs } from '../types';

import BusinessChallengeProjectBudget from './business-challenge-project-budget';

const SdgObjectivesOptions = (props: any) => {
  const { data, isSuccess } = useGetSdgObjectivesQuery()
  return (
    <>
      {isSuccess && props.render(data)}
    </>
  )
}

const GoalsOptions = (props: any) => {
  const { data, isSuccess } = useGetGoalsQuery()
  return (
    <>
      {isSuccess && props.render(data)}
    </>);
}

const ImpactAreaOptions = (props: any) => {
  const { data, isSuccess } = useGetImpactAreasQuery();
  return (
    <>
      {isSuccess && props.render(data)}
    </>);
}

const BusinessUseCasesOptions = (props: any) => {
  const { data, isSuccess } = useGetBusinessUsecasesQuery()
  return (
    <>
      {isSuccess && props.render(data)}
    </>);
}

const TechnologyTypesOptions = (props: any) => {
  const { data, isSuccess } = useGetTechnologyTypesQuery()
  return (
    <>
      {isSuccess && props.render(data)}
    </>);
}

const BusinessChallenge = () => {
  const { onboardingPayload, setOnboardingPayload } = useOnboarding()
  const { currentStep, nextStep } = useSteps()
  
  // todo - review and optimise
  const [isOtherImportantOptionChecked, setIsOtherImportantOptionChecked] = useState(false);
  const [isTechnologySolutionModelOthersChecked, setIsTechnologySolutionModelOthersChecked] = useState(false);
  const [isImportantOptionsValid, setIsImportantOptionsValid] = useState(false);
  const [isSolutionOptionsValid, setIsSolutionOptionsValid] = useState(false);
  const [isProjectBudgetValid, setIsProjectBudgetValid] = useState(false);
  
  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { errors, isSubmitted } } = useForm<BusinessChallengeInputs>({
        mode: "onChange",
    });

  const transformValues = (values: BusinessChallengeInputs): OnboardingSessionCorporate_TechnologyRequest => {
      return {
        name: values?.name ?? '',
        description_project: values?.description_project ?? '',
        business_challange_description: values?.business_challange_description ?? '',
        goals: values?.goals?.map(item => parseInt(item)),
        has_api: values?.has_api ?? false,
        has_ip: values?.has_ip ?? false,
        is_gdpr: values?.is_gdpr ?? false,
        is_spin_off: values?.is_spin_off ?? false,
        can_whitelabel: values?.can_whitelabel ?? false,
        is_plugandplay: values?.is_plugandplay ?? false,
        is_cloud: values?.is_cloud ?? false,
        sdg_driven: values?.sdg_driven ?? false,
        is_others: values?.is_others ?? '',
        technology_solution_model_b2b: values?.technology_solution_model_b2b ?? false,
        technology_solution_model_b2c: values?.technology_solution_model_b2c ?? false,
        technology_solution_model_b2b2c: values?.technology_solution_model_b2b2c ?? false,
        technology_solution_model_others: values?.technology_solution_model_others ?? '',
        impact_level: values?.impact_level ?? '',
        impact_area: parseInt(values?.impact_area ?? '0'),
        business_use_cases: values?.business_use_cases?.map(item => parseInt(item)),
        technology_types: values?.technology_types?.map(item => parseInt(item)),
        project_budget: values?.project_budget?.map(item => ({
            currency: item?.currency ?? '',
            max_budget: parseInt(item?.budget.toString() ?? '0'),
            option: item?.option ?? ''
        })),
        sdg_objectives: values?.sdg_objectives?.map(item => parseInt(item)),
      } as OnboardingSessionCorporate_TechnologyRequest;
  }

  const skipThisSection = async () => {
    await setOnboardingPayload({
      step: currentStep + 1
    })
    nextStep()
  }

  const handlePrev = async (prevStep: () => unknown) => {
    const businessChallenge = transformValues(getValues());
    await setOnboardingPayload({
      technologyRequest: businessChallenge,
      step: currentStep - 1
    })
    prevStep()
  }

  const handleNext = async (nextStep: () => unknown) => {
    const valid = await trigger();
    if (valid) {
      const businessChallenge = transformValues(getValues());
      await setOnboardingPayload({
        technologyRequest: businessChallenge,
        step: currentStep + 1
      })
      nextStep()
    }
  }
  
  useEffect(() => {
    const data = onboardingPayload.technologyRequest as OnboardingSessionCorporate_TechnologyRequest;
    if (data) {
      reset({
        name: data?.name ?? '',
        description_project: data?.description_project ?? '',
        business_challange_description: data?.business_challange_description ?? '',
        goals: data?.goals?.map(item => item?.toString()),
        has_api: data?.has_api ?? false,
        has_ip: data?.has_ip ?? false,
        is_gdpr: data?.is_gdpr ?? false,
        is_spin_off: data?.is_spin_off ?? false,
        can_whitelabel: data?.can_whitelabel ?? false,
        is_plugandplay: data?.is_plugandplay ?? false,
        is_cloud: data?.is_cloud ?? false,
        is_others: data?.is_others ?? '',
        technology_solution_model_b2b: data?.technology_solution_model_b2b ?? false,
        technology_solution_model_b2c: data?.technology_solution_model_b2c ?? false,
        technology_solution_model_b2b2c: data?.technology_solution_model_b2b2c ?? false,
        technology_solution_model_others: data?.technology_solution_model_others ?? '',
        impact_level: data?.impact_level ?? '',
        impact_area: data?.impact_area?.toString() ?? '',
        business_use_cases: data?.business_use_cases?.map(item => item?.toString()),
        technology_types: data?.technology_types?.map(item => item?.toString()),
        project_budget: data?.project_budget?.map(item => ({
            currency: item?.currency ?? '',
            max_budget: parseInt(item?.max_budget?.toString() ?? '0'),
            option: item?.option ?? ''
        })),
        sdg_objectives: data?.sdg_objectives?.map(item => item?.toString()),
      });

      setIsOtherImportantOptionChecked(data?.is_others !== '' && data?.is_others !== null && data?.is_others !== undefined);
    }
  }, [onboardingPayload])

  return (
    <FormLayout
      subHeading="Corporate onboarding"
      heading="My Business Need"
    >
      <form className="form-layout-form">
        <div className="row">
          <div className="col">
            <span className="mr-2 color-nightsky bold-type">
              <b>Want to explore first?</b>
            </span>
            <button
              type="button" 
              className="btn btn-link-bordered bold-type font-size-12 p-3" 
              style={{ marginLeft: 18 }}
              onClick={() => skipThisSection()}
            >
              Skip this section
            </button>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <div className="form-field">
              <label>Name of your project *</label>
              <input type="text"
                  {...register("name", { required: true })}
              />
              <div className={`invalid-feedback ${errors.name ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>Description of the project. What are you looking for? *</label>
              <textarea
                rows={3}
                placeholder="Your response goes here"
                {...register("description_project", { required: true })} ></textarea>
              <div className={`invalid-feedback ${errors.description_project ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label className="line-height-140">Description of your business need /challenge / opportunity. What are you trying to resolve/ tackle? *</label>
              <textarea
                rows={3}
                placeholder="Your response goes here"
                {...register("business_challange_description", { required: true })} ></textarea>
              <div className={`invalid-feedback ${errors.business_challange_description ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>Goal</label>
              <GoalsOptions
                render={(goals: Array<OptionItem>) => (
                  <Controller
                    name="goals"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <SelectMultiple
                      variant="thicker"
                      options={toOptions(goals)}
                      {...field} />}
                  />
                )}
              />
              <div className={`invalid-feedback ${errors.goals ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label>Options that are important for you *</label>
              <div className={`invalid-feedback ${isSubmitted && !isImportantOptionsValid ? 'd-block' : ''}`}>Please select at least one </div>
              <div className="mt-2 mb-3">
                <Controller
                  name="has_api"
                  control={control}
                  render={({ field }) => <InputCheckBox className="" 
                    labelClassName="medium-type m-0" label="API available"
                      {...field} />}
                />
              </div>
              <div className="mt-2 mb-3">
                <Controller
                  name="sdg_driven"
                  control={control}
                  render={({ field }) => <InputCheckBox className="" 
                    labelClassName="medium-type m-0" label="SDG Driven"
                      {...field} />}
                />
              </div>
              <div className="mt-2 mb-3">
                <Controller
                  name="has_ip"
                  control={control}
                  render={({ field }) => <InputCheckBox className="" labelClassName="medium-type m-0" label="Registered IP"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3">
                <Controller
                  name="is_gdpr"
                  control={control}
                  render={({ field }) => <InputCheckBox className="" labelClassName="medium-type m-0" label="GDPR compliant"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3">
                <Controller
                  name="is_spin_off"
                  control={control}
                  render={({ field }) => <InputCheckBox className="" labelClassName="medium-type m-0" label="Spin-off / Tested"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3">
                <Controller
                  name="can_whitelabel"
                  control={control}
                  render={({ field }) => <InputCheckBox className="" labelClassName="medium-type m-0" label="White label available"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3">
                <Controller
                  name="is_plugandplay"
                  control={control}
                  render={({ field }) => <InputCheckBox className="" labelClassName="medium-type m-0" label="Ready to Plug & Play"
                      {...field} />}
                />
              </div>
              <div className="mt-2 mb-3">
                <Controller
                  name="is_cloud"
                  control={control}
                  render={({ field }) => <InputCheckBox className="" labelClassName="medium-type m-0" label="Cloud based"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3">
                <InputCheckBox
                  className=""
                  labelClassName="medium-type m-0"
                  label="Other"
                  value={isOtherImportantOptionChecked}
                  onChange={(event) => {
                    setIsOtherImportantOptionChecked(!isOtherImportantOptionChecked);
                  }}
                />
              </div>
              <div className="mt-2 mb-3">
                <input type="text"
                  placeholder="Other option - enter here"
                  required={isOtherImportantOptionChecked}
                  style={{ display: isOtherImportantOptionChecked ? '' : 'none' }}
                  {...register("is_others")} />
                <div className={`invalid-feedback ${isSubmitted && isOtherImportantOptionChecked ? 'd-block' : ''}`}>This field is required</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label>Looking for a solution *</label>
              <div className={`invalid-feedback ${isSubmitted && !isSolutionOptionsValid ? 'd-block' : ''}`}>Please select at least one </div>
              <div className="mt-2 mb-3">
                <Controller
                  name="technology_solution_model_b2b"
                  control={control}
                  render={({ field }) => <InputCheckBox className="" labelClassName="medium-type m-0" label="BtoB"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3">
                <Controller
                  name="technology_solution_model_b2b2c"
                  control={control}
                  render={({ field }) => <InputCheckBox className="" 
                  labelClassName="medium-type m-0" label="BtoBtoC"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3">
                <Controller
                  name="technology_solution_model_b2c"
                  control={control}
                  render={({ field }) => <InputCheckBox className="" 
                  labelClassName="medium-type m-0" label="BtoC"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3">
                <div className="">
                  <InputCheckBox
                    className=""
                    labelClassName="medium-type m-0"
                    label="Other"
                    value={isTechnologySolutionModelOthersChecked}
                    onChange={(event) => {
                      setIsTechnologySolutionModelOthersChecked(!isTechnologySolutionModelOthersChecked);
                    }}
                  />
                </div>
                <div className="mt-2 mb-3">
                  <input type="text"
                    placeholder="Other option - enter here"
                    required={isTechnologySolutionModelOthersChecked}
                    style={{ display: isTechnologySolutionModelOthersChecked ? '' : 'none' }}
                    {...register("technology_solution_model_others")} />
                  <div className={`invalid-feedback ${isSubmitted && isTechnologySolutionModelOthersChecked ? 'd-block' : ''}`}>This field is required</div>
                </div>
              </div>
            </div>
            <div className="form-field">
              <label className="bold-type color-nightsky font-size-14 text-normal">Impact for your organisation</label>
              <Controller
                name="impact_level"
                control={control}
                render={({ field }) => <SelectOne
                  variant="thicker"
                  className="mt-3"
                  options={ImpactLevels as Array<string>}
                  {...field} />}
              />
            </div>
            <div className="form-field">
              <label className="bold-type color-nightsky font-size-14 text-normal">Horizon</label>
              <ImpactAreaOptions
                render={(impactAreas: Array<ImpactArea>) => (
                  <Controller
                    name="impact_area"
                    control={control}
                    render={({ field }) => <SelectOne
                      variant="thicker"
                      className="mt-3"
                      options={toOptions(impactAreas)}
                      {...field} />}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>Potential business use case / applications</label>
              <BusinessUseCasesOptions
                render={(businessUseCases: Array<BusinessUseCase>) => (
                  <Controller
                    name="business_use_cases"
                    control={control}
                    render={({ field }) => <SelectMultiple
                      variant="thicker"
                      options={toOptions(businessUseCases)}
                      {...field} />}
                  />
                )}
              />
            </div>
          </div>
        </div><div className="row">
          <div className="col">
            <div className="form-field">
              <label>SDG TARGET</label>
              <SdgObjectivesOptions
                render={(sdgObjectives: Array<SdgObjectives>) => (
                  <Controller
                    name="sdg_objectives"
                    control={control}
                    render={({ field }) => <SelectMultiple
                      variant="thicker"
                      options={toOptions(sdgObjectives)}
                      {...field} />}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>Type of Technology</label>
              <TechnologyTypesOptions
                render={(technologyTypes: Array<TechnologyType>) => (
                  <Controller
                    name="technology_types"
                    control={control}
                    render={({ field }) => <SelectMultiple
                      variant="thicker"
                      options={toOptions(technologyTypes)}
                      {...field}
                    />}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <BusinessChallengeProjectBudget {...{ control, register, errors }} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ActionBar onPrev={handlePrev} onNext={handleNext} />
          </div>
        </div>
      </form>
    </FormLayout>
  );
}

export default BusinessChallenge;