import React from 'react';
import { Column } from './column';
import { Row } from './row';
import { Footer } from './footer';
import { RowProps, TableProps } from './types';
import './assets/styles/table.scss';

export const Table = ({
  headers,
  rows,
  showHeader = false,
  showRowDelete = true,
  showFooter = true,
  footer = null,
  onDelete = () => {},
  emptyRowsContent = null
}: TableProps) => {

  const columnLength = headers.length + (showRowDelete ? 1 : 0)

  return (
    <table className="table">
      {showHeader && <thead>
        <tr>
          {showRowDelete && <Column label='' />}
          {headers.map((header, i) => <Column key={i} {...header} />)}  
        </tr>
      </thead>}
      <tbody>
        {rows.length === 0 && <tr><td colSpan={columnLength}>{emptyRowsContent}</td></tr>}
        {rows.map(({values}: RowProps, i) => <Row 
          key={`row-${i.toString()}`}
          showRowDelete={showRowDelete} 
          values={values}
          onDelete={(key: string) => {
            onDelete && onDelete(key);
          }}
        />)}
      </tbody>
      {showFooter && <Footer
        columnLength={columnLength} 
        content={footer} />}
    </table>
  )
}
