import React from "react";
import "../assets/styles/conversation.scss";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import Pill from "app/shared/pill";
import TickWhiteIcon from "app/shared/assets/svgs/tick-white.svg";
import Tooltip from "@material-ui/core/Tooltip";
import Inputs from "./inputs";
import { useGetGoalsQuery } from "services/graphql/tags";
import { toOptions } from "shared/utilities";
import { OptionItem } from "shared/forms/select-multiple";
import SelectMultiple from "shared/forms/select-multiple";
import { Control, UseFormRegister, Controller } from "react-hook-form";
import { trackEvent } from 'shared/mixpanel';
type BusinessUseCaseProps = {
  view: string;
  business_use_case: any;
  register: UseFormRegister<Inputs>;
  control: Control<Inputs>;
};

const BusinessUseCase = React.forwardRef(
  (
    { view, business_use_case, register, control }: BusinessUseCaseProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const description = business_use_case?.description_project;
    const challenge = business_use_case?.business_challenge;
    const selectedGoals = business_use_case?.goals;
    const resolve = business_use_case?.how_to_resolve;
    const customer = business_use_case?.customer;
    const successful_outcome = business_use_case?.successful_outcome;
    const other_solutions = business_use_case?.other_solutions;
    const risks = business_use_case?.risks;

    const GoalsOptions = (props: any) => {
      const { data, isSuccess } = useGetGoalsQuery();

      return <>{isSuccess && props.render(data)}</>;
    };
    
    React.useEffect(() => {
      trackEvent({eventName: 'conversation_business_use_case'});
    }, []);

    return (
      <Box
        border={1}
        borderColor="grey.300"
        borderRadius={10}
        className="mainBoard"
      >
        {view === "startup" ? (
          <div className="section">
            <label>
              <b>Description of project</b>
            </label>

            <div className="body">
              {description === null
                ? "None"
                : description}
            </div>

            <label>
              <b>
                Description of the business challenge/opportunity
              </b>
            </label>

            <div className="body">
              {challenge === null ? "None" : challenge}
            </div>
            <label>
              <b>Goal</b>
            </label>
            <Grid container spacing={1}>

              {selectedGoals?.length === 0 ? (
                <div className="body">
                  None
                </div>
              ) : (
                selectedGoals?.map((g: any) => (
                  <Tooltip title={g?.Name || ""}>
                    <div className="chipill">
                      <Pill icon={TickWhiteIcon} label={g?.Name || ""} />
                    </div>
                  </Tooltip>
                ))
              )}
            </Grid>

            <label>
              <b>
                How to resolve the problem/tackle that opportunity
              </b>
            </label>
            <div className="body">
              {resolve === null ? "None" : resolve}
            </div>
            <label>
              <b>
                Who would be the customer &amp; who would be using the solution?
              </b>
            </label>
            <div className="body">
              {customer === null ? "None" : customer}
            </div>
            <label>
              <b>
                What would be the successful outcome of this process/solution?
              </b>
            </label>
            <div className="body">
              {successful_outcome === null
                ? "None"
                : successful_outcome}
            </div>
            <label>
              <b>
                Are there any solutions similar in the market to achieve that?
              </b>
            </label>
            <div className="body last">
              {other_solutions === null
                ? "None"
                : other_solutions}
            </div>
            <label>
              <b>
                Are there any risks or challenges to take into account for this project?
              </b>
            </label>
            <div className="body last">
              {risks === null
                ? "None"
                : risks}
            </div>
          </div>
        ) : (
          <div className="section">
            <label>
              <b>Description of project</b>
            </label>

            <textarea
              defaultValue={description || ""}
              {...register("business_use_case.description_project")}
            />

            <label>
              <b>
                Description of the business challenge/opportunity
              </b>
            </label>
            <textarea
              defaultValue={challenge || ""}
              {...register("business_use_case.business_challenge")}
            />
            <Grid container style={{ marginTop: '32px' }}>
              <Grid item xs={12}>
                <label>
                  <b>Goal</b>
                </label>
                <GoalsOptions
                  render={(goals: Array<OptionItem>) => (
                    <Controller
                      name="business_use_case.goals"
                      control={control}
                      defaultValue={
                        selectedGoals
                          ? selectedGoals.map((item: any) => String(item.id))
                          : []
                      }
                      render={({ field }) => (
                        <SelectMultiple
                          options={toOptions(goals)}
                          {...field}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <label>
              <b>
                How to resolve the problem/tackle that opportunity
              </b>
            </label>

            <textarea
              defaultValue={resolve || ""}
              {...register("business_use_case.how_to_resolve")}
            />
            <label>
              <b>
                Who would be the customer &amp; who would be using the solution?
              </b>
            </label>

            <textarea
              defaultValue={customer || ""}
              {...register("business_use_case.customer")}
            />
            <label>
              <b>
                What would be the successful outcome of this process/solution?
              </b>
            </label>
            <textarea
              defaultValue={successful_outcome || ""}
              {...register("business_use_case.successful_outcome")}
            />
            <label>
              <b>
                Are there any solutions similar in the market to achieve that?
              </b>
            </label>
            <textarea
              style={{ margin: '0px' }}
              defaultValue={other_solutions || ""}
              {...register("business_use_case.other_solutions")}
            />
            <label>
              <b>
                Are there any risks or challenges to take into account for this project?
              </b>
            </label>
            <textarea
              style={{ margin: '0px' }}
              defaultValue={risks || ""}
              {...register("business_use_case.risks")}
            />
          </div>
        )}
      </Box>
    );
  }
);

export default BusinessUseCase;
