import { useEffect } from 'react';
import FormLayout from './form-layout';
import ActionBar from '../action-bar/action-bar';
import { useForm, Controller, UseFormWatch, useWatch, Control, UseFormRegister } from 'react-hook-form';
import './assets/styles/form-layout-form.scss';
import { StartupTechSolutionInputs } from '../types'

import useOnboarding from '../hooks/onboarding.hook'
import useSteps from '../hooks/steps.hook'
import {
  BusinessUseCase,
  CorporateDepartment,
  Industry,
  TechnologyType,
  OnboardingSessionStartup_TechnologySolution,
  TechnologyReadinessLevel,
  OnboardingSessionStartup_TechnologySolution_PricingModels,
  SdgObjectives
} from 'generated/graphql';

import {
  useGetBusinessUsecasesQuery,
  useGetCorporateDepartmentsQuery,
  useGetIndustriesQuery,
  useGetTechnologyTypesQuery,
  useGetTechnologyReadinessLevelsQuery,
  useGetSdgObjectivesQuery
} from 'services/graphql/tags';

import WarningTriangleIcon from 'app/shared/assets/svgs/warning-triangle-orange.svg';
import IconLabel from 'app/shared/icon-label';
import InputCheckBox from 'shared/forms/input-checkbox';
import SelectMultiple from 'shared/forms/select-multiple';
import SelectOne from 'shared/forms/select-one';

import { toOptions, toValues, unNull, useWindowDimensions } from 'shared/utilities'

import TechnologySolutionProjectBudget from './technology-solution-project-budget'

const SdgObjectivesOptions = (props: any) => {
  const { data, isSuccess } = useGetSdgObjectivesQuery()
  return (
    <>
      {isSuccess && props.render(data)}
    </>
  )
}

const TechnologyTypesOptions = (props: any) => {
  const { data, isSuccess } = useGetTechnologyTypesQuery()
  return (
      <>
          {isSuccess && props.render(data)}
      </>);
}

const TechnologyReadinessOptions = (props: any) => {
  const { data, isSuccess } = useGetTechnologyReadinessLevelsQuery()
  return (
      <>
          {isSuccess && props.render(data)}
      </>);
}

const IndustriesOptions = (props: any) => {
  const { data, isSuccess } = useGetIndustriesQuery()
  return (
      <>
          {isSuccess && props.render(data)}
      </>);
}

const CorporateDepartmentsOptions = (props: any) => {
  const { data, isSuccess } = useGetCorporateDepartmentsQuery()
  return (
      <>
          {isSuccess && props.render(data)}
      </>);
}

const BusinessUseCasesOptions = (props: any) => {
  const { data, isSuccess } = useGetBusinessUsecasesQuery()
  return (
      <>
          {isSuccess && props.render(data)}
      </>);
}


type SpinOffWatchedProps = {
  control: Control<StartupTechSolutionInputs>;
  register: UseFormRegister<StartupTechSolutionInputs>;
  spinOffSource: string;
  watchIsSpinOff: boolean;
}

function SpinOffWatched({ 
  control,
  register,
  spinOffSource,
  watchIsSpinOff
}: SpinOffWatchedProps) {
  const isSpinOff = useWatch({
    control,
    name: "is_spin_off", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: false // default value before the render
  });



  return (
    <div className="mb-4 form-check" style={{ display: watchIsSpinOff ? '' : 'none' }}>
      <label className="w-100">
        <input type="text"
          placeholder="Name of spin-off"
          defaultValue={spinOffSource}
          {...register("spin_off_source")} />
      </label>
    </div>
  );
}


const MyTechSolution = () => {
  const { setOnboardingPayload, onboardingPayload } = useOnboarding();
  const { windowHeight, windowWidth } = useWindowDimensions();
  const { currentStep } = useSteps();
  
  const {
    register,
    control,
    getValues,
    setValue,
    reset,
    watch,
    trigger,
    formState: { errors } } = useForm<StartupTechSolutionInputs>({
      mode: "onChange",
    });

  const watchIsSpinOff = watch('is_spin_off')

  const transformValues = (values: StartupTechSolutionInputs): OnboardingSessionStartup_TechnologySolution => {
    return {
        name: values?.name ?? '',
        description: values?.description ?? '',
        has_api: values?.has_api ?? false,
        has_ip: values?.has_ip ?? false,
        is_gdpr: values?.is_gdpr ?? false,
        is_whitelabel: values?.is_whitelabel ?? false,
        is_plugandplay: values?.is_plugandplay ?? false,
        is_cloud: values?.is_cloud ?? false,
        is_spin_off: values?.is_spin_off ?? false,
        spin_off_source: values?.spin_off_source ?? '',
        technology_readiness: values?.technology_readiness ?? '',
        technology_types: values?.technology_types?.map(item => parseInt(item)),
        business_use_cases: values?.business_use_cases?.map(item => parseInt(item)),
        industries: values?.industries?.map(item => parseInt(item)),
        corporate_departments: values?.corporate_departments?.map(item => parseInt(item)),
        technology_solution_pricing_model: values?.technology_solution_pricing_model?.map(item => ({
            currency: item?.currency ?? '',
            min_budget: item?.min_budget ?? 0,
            option: item?.option ?? '',
            pricing_model: parseInt(item?.pricing_model ?? '0'),
        })),
        sdg_objectives: values?.sdg_objectives?.map(item => parseInt(item)),
    } as OnboardingSessionStartup_TechnologySolution
  }

  const handlePrev = async (prevStep: () => unknown) => {
    const myTechSolution = transformValues(getValues());
    await setOnboardingPayload({
      technologySolution: myTechSolution,
      step: currentStep - 1
    })
    prevStep()
  }

  const handleNext = async (nextStep: () => unknown) => {
    const valid = await trigger();
    if (valid) {
      const myTechSolution = transformValues(getValues());
      await setOnboardingPayload({
        technologySolution: myTechSolution,
        step: currentStep + 1
      })

      nextStep()
    }
  }

  useEffect(() => {
    const data = onboardingPayload.technologySolution as OnboardingSessionStartup_TechnologySolution;
    if (data) {
      reset({
        name: data?.name ?? '',
        description: data?.description ?? '',
        has_api: data?.has_api ?? false,
        has_ip: data?.has_ip ?? false,
        is_gdpr: data?.is_gdpr ?? false,
        is_whitelabel: data?.is_whitelabel ?? false,
        is_plugandplay: data?.is_plugandplay ?? false,
        is_cloud: data?.is_cloud ?? false,
        is_spin_off: data?.is_spin_off ?? false,
        spin_off_source: data?.spin_off_source ?? '',
        technology_readiness: data?.technology_readiness ?? '0',
        technology_types: data?.technology_types?.map(item => item?.toString()),
        business_use_cases: data?.business_use_cases?.map(item => item?.toString()),
        industries: data?.industries?.map(item => item?.toString()),
        corporate_departments: data?.corporate_departments?.map(item => item?.toString()),
        technology_solution_pricing_model: data?.technology_solution_pricing_model?.map(item => ({
            currency: item?.currency ?? '',
            min_budget: item?.min_budget ?? 0,
            option: item?.option ?? '',
            pricing_model: (item?.pricing_model ?? '').toString(),
        })),
        sdg_objectives: data?.sdg_objectives?.map(item => item?.toString()),
    });
    }
}, [onboardingPayload])

  return (
    <FormLayout
      subHeading="Startup onboarding"
      heading="My Tech Solution"
    >
      <form className="form-layout-form">
        <div className="row">
          <div className="col">
            <div className="mb-5 alert alert-tangerine" role="alert">
              <div className="d-flex flex-row">
                <div className="me-2">
                  <IconLabel icon={WarningTriangleIcon}></IconLabel>
                </div>
                <p className="m-0">
                  We kindly request the information to be provided <b>anonymously</b>, without any identifiable markers.<br/>
                  Please don't mention your company or any specific / traded wording. Thank you!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>Name of your tech solution *</label>
              <input type="text"
                {...register("name", { required: true })}
              />
              <div className={`invalid-feedback ${errors.name ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="form-field">
              <label>Describe your tech solution *</label>
              <textarea
                rows={windowWidth > 500 ? 13 : 5}
                placeholder="Your response goes here"
                {...register("description", { required: true })} ></textarea>
              <div className={`invalid-feedback ${errors.description ? 'd-block' : ''}`}>This field is required</div>
            </div>
            <div className="form-field">
              <label>Technology Readiness Level *</label>
                <TechnologyReadinessOptions
                  render={(technologyReadinessLevels: Array<TechnologyReadinessLevel>) => (
                    <Controller
                      name="technology_readiness"
                      control={control}
                      render={({ field }) => <SelectOne
                        variant="thicker"
                        options={toOptions(technologyReadinessLevels)}
                        {...field} />}
                    />
                  )}
                />
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-field">
              <label>Options that apply to your tech solution *</label>
              <div className="mt-2 mb-3 form-check">
                <Controller
                  name="has_api"
                  control={control}
                  render={({ field }) => <InputCheckBox label="API available"
                    labelClassName="thicker m-0"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3 form-check">
                <Controller
                  name="sdg_driven"
                  control={control}
                  render={({ field }) => <InputCheckBox label="SDG Driven"
                    labelClassName="thicker m-0"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3 form-check">
                <Controller
                  name="is_whitelabel"
                  control={control}
                  render={({ field }) => <InputCheckBox label="White label available"
                    labelClassName="thicker m-0"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3 form-check">
                <Controller
                  name="has_ip"
                  control={control}
                  render={({ field }) => <InputCheckBox label="Registered IP"
                    labelClassName="thicker m-0"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3 form-check">
                <Controller
                  name="is_plugandplay"
                  control={control}
                  render={({ field }) => <InputCheckBox label="Ready to Plug &amp; Play"
                    labelClassName="thicker m-0"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3 form-check">
                <Controller
                  name="is_gdpr"
                  control={control}
                  render={({ field }) => <InputCheckBox label="GDPR"
                    labelClassName="thicker m-0"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3 form-check">
                <Controller
                  name="is_cloud"
                  control={control}
                  render={({ field }) => <InputCheckBox label="Cloud"
                    labelClassName="thicker m-0"
                    {...field} />}
                />
              </div>
              <div className="mt-2 mb-3 form-check">
                <Controller
                  name="is_spin_off"
                  control={control}
                  render={({ field }) => <InputCheckBox label="Spin-off / Tested"
                    labelClassName="thicker m-0"
                    {...field} />}
                />
              </div>
              <SpinOffWatched 
                control={control} 
                register={register}
                watchIsSpinOff={watchIsSpinOff}
                spinOffSource={onboardingPayload.spin_off_source} 
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>Type of technology *</label>
              <TechnologyTypesOptions
                render={(technologyTypes: Array<TechnologyType>) => (
                  <Controller
                    name="technology_types"
                    control={control}
                    render={({ field }) => <SelectMultiple
                      variant="thicker"
                      options={toOptions(technologyTypes)}
                      {...field}
                    />}
                  />
                )} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>Potential business/use cases *</label>
              <BusinessUseCasesOptions
                render={(businessUseCases: Array<BusinessUseCase>) => (
                  <Controller
                    name="business_use_cases"
                    control={control}
                    render={({ field }) => <SelectMultiple
                      variant="thicker"
                      options={toOptions(businessUseCases)}
                      {...field} />}
                  />
                )}
            />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>SDG Focus</label>
              <SdgObjectivesOptions
                render={(sdgObjectives: Array<SdgObjectives>) => (
                  <Controller
                    name="sdg_objectives"
                    control={control}
                    render={({ field }) => <SelectMultiple
                      variant="thicker"
                      options={toOptions(sdgObjectives)}
                      {...field} />}
                  />
                )}
            />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>Potential industries interested *</label>
              <IndustriesOptions
                render={(industries: Array<Industry>) => (
                  <Controller
                    name="industries"
                    control={control}
                    render={({ field }) => <SelectMultiple
                      variant="thicker"
                      options={toOptions(industries)}
                      {...field} />}
                  />
                )}
            />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>Potential departments interested</label>
              <CorporateDepartmentsOptions
                render={(corporateDepartments: Array<CorporateDepartment>) => (
                  <Controller
                    name="corporate_departments"
                    control={control}
                    render={({ field }) => <SelectMultiple
                      variant="thicker"
                      options={toOptions(corporateDepartments)}
                      {...field} />}
                  />
                )}
            />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TechnologySolutionProjectBudget
              {...{ control, register, errors }}
            />
          </div>
        </div>
        
        <div className="row">
          <div className="col">
            <ActionBar onPrev={handlePrev} onNext={handleNext} />
          </div>
        </div>
      </form>
    </FormLayout>
  )
}

export default MyTechSolution;