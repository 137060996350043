import React from 'react';

const ErrorPage = () => {
  return (
    <div>
      <p>Oops, Our support team is investing this issue. please kindly bear with us as we get this page back online.</p>
    </div>
  );
};

export {
  ErrorPage
}