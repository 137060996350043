import React, { useState, useEffect } from "react";

import StripeCheckout from './credit-card/stripe/stripe-checkout'
import { useSelector } from 'react-redux'
import { currentCart } from 'features/cart/cartSlice';

const CreditCard = () => {
  const cart = useSelector(currentCart);

  return (
    <>
      <StripeCheckout/>
    </>
  )
}

export default CreditCard