"use client";

import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { useCreatePaymentIntentMutation } from 'services/graphql/payments'
import { useGetCurrencyQuery } from 'services/graphql/settings'

import CheckoutForm from './checkout-form';

import { useSelector } from 'react-redux'
import { currentCart } from 'features/cart/cartSlice';
import { payment_stripe_key } from "features/app-config/appConfigSlice";

import { PaymentItemType } from 'generated/graphql'

export default function StripeCheckout() {
  const [clientError, setClientError] = useState<string>("");
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [ create, status ] = useCreatePaymentIntentMutation()
  const cart = useSelector(currentCart);
  const { data, isSuccess } = useGetCurrencyQuery()
  const paymentStripeKey = useSelector(payment_stripe_key)

  useEffect(() => {
    // initialize payment intent and display the widget
    if (isSuccess && paymentStripeKey && cart.items.length > 0) {
      console.log('creating payment intent', data, cart.items)
      const createPay = async () => {
        try {
          const { client_secret } = await ( await create({
            items: cart.items.map(({
                slug,
                qty,
                itemType
              }) => ({
                itemId: slug,
                type: itemType === 'SERVICE_PACKAGE' ? PaymentItemType.Credit : PaymentItemType.Service,
                quantity: qty
              })),
            currency: (data?.currency as string).toLowerCase(), 
            // currency has to be configured in strapi to be valid according to currency code standards
            method: 'CREDIT_CARD'
          }).unwrap())
          
          if (client_secret)
            setClientSecret(client_secret as string)
        } catch (error) {
          setClientError('Error starting Credit Card payment')
        }
      }
      if (data && isSuccess) {
        createPay()
      }
    }
  }, [data, isSuccess, paymentStripeKey]);

  
  useEffect(() => {
    // catch an error here if stripe returns an error
    if (status.isError) {
      console.log(status.error)
      // throw new Error(status.error.message)
    }
  }, [status])
  
  useEffect(() => {
    // initialize the stripe key from the state
    if (paymentStripeKey) {
      const stripePromise = loadStripe(paymentStripeKey);
      setStripePromise(stripePromise)
    }
  }, [paymentStripeKey])

  return (
    <>
      {clientSecret && (
        <Elements options={{
          clientSecret,
          appearance: {
            theme: 'stripe'
          }
        }} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
      {clientError && (
        <div>
          {clientError}
        </div>
      )}
    </>
  );
}