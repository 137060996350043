import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { RedeemStartConversationInput, RedeemStartConversationPayload } from 'generated/graphql'

export const promoCodeConversationApi = createApi({
  reducerPath: "promoCodeConversationApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    usePromoCodeStartConversation: builder.mutation<RedeemStartConversationPayload, RedeemStartConversationInput>({
      query: (input) => ({
        document: gql`
          mutation redeemStartConversation($input: RedeemStartConversationInput!) {
            redeemStartConversationCode(input: $input) {
              result
              conversationId
            }
          }
        `,
        variables: {
          input
        }
      }),
      transformResponse: (response: any) => response.redeemStartConversationCode,
    })
  })
})

export const {
  useUsePromoCodeStartConversationMutation
} = promoCodeConversationApi;
