"use client";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Home from './home';
import Jobs from './jobs';
import TermsConditions from './terms-conditions';
import { Privacy } from './privacy';
import Application from './app/application';
import { selectCurrentToken } from 'features/auth/authSlice';
import Session from './session';
import Logout from './app/logout';
import ToastsNotifications from 'shared/toast';

import * as Sentry from '@sentry/react';
import { initialize, trackEvent } from './shared/mixpanel';
import { useEffect } from 'react';

const useAuth = () => {
  const token = useSelector(selectCurrentToken)
  return useMemo(() => ({token}), [token])
}

const history = createBrowserHistory();
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV5BrowserTracingIntegration({
      history
    }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0, // Adjust this value in production
});

// Near entry of your product, init Mixpanel
initialize();

const PrivateRoute = ({children, ...rest}: any) => {
  const { token } = useAuth();
  const session = Session.get()
  const isLoggedIn = token || session
  
  return (
    <Route {...rest}
      render={({location}) => {
        return (
          isLoggedIn ? (
              children
          ) : (
            <Redirect 
              to={{
                pathname: "/login",
                state: { from: location }
              }}
              />
          )
        )
      }}
    />
  )
}

const App = () => {
  const token = Session.get();

  return (
    <Router>
      {false && token ? <Redirect to="/app"/> : null}
      <Switch>
        <Route exact path={[
            '/', 
            '/login', 
            '/forgot-password', 
            '/reset-password',
            '/adhoc-service-corporate', 
            '/adhoc-service-startup',
            '/contact'
            ]}>
          <Home />
        </Route>
        <Route exact path="/jobs">
          <Jobs />
        </Route>
        <Route exact path="/terms-conditions">
          <TermsConditions />
        </Route>
        <Route exact path="/privacy">
          <Privacy />
        </Route>
        
        <Route exact path="/reset-password/:code">
          <Home />
        </Route>
        <PrivateRoute path="/app/:entityType?">
          <Application />
        </PrivateRoute>
        <Route path="/logout">
          <Logout/>
        </Route>
      </Switch>
      <ToastsNotifications />
    </Router>
  );
}

export default App;
