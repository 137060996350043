import React from 'react';
import {useParams} from 'react-router-dom';import '../assets/styles/profile-page.scss';

import Tile from 'app/shared/tile';
import { Image } from 'shared/image';

import InputCheckBox from "shared/forms/input-checkbox";
import InputImage from "shared/forms/gray-image";
import Pill from "app/shared/pill";
import XWhiteIcon from "app/shared/assets/svgs/x-white.svg";
import BoxTick from "app/shared/assets/svgs/BoxCheckBlue.svg";
import BoxCheck from "app/shared/assets/svgs/BoxXBlue.svg";

import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import {useGetConversationStartupProfileQuery} from 'services/graphql/conversation';
import { Countries } from 'shared/countries';
import { trackEvent } from 'shared/mixpanel';

const StartupProfile = () => {

    const { id }: any = useParams()
    const { data, isLoading, isSuccess } = useGetConversationStartupProfileQuery(id);
    React.useEffect(() => {
      console.log("Conv ID", id);
      console.log("Startup Profile:", data);
    }, [data,id]);

    const numberWithCommas = (x :number) => {
        if(x){
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        }
    }

    React.useEffect(() => {
      trackEvent({eventName: 'conversation_startup_profile_view', properties: {id: id}});
    }, []);

    return (
        <div className="container-fluid">
            {/* <div className="row"> */}
            <Tile>
                <h3> {data?.name || "Profile Name"}</h3>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="stretch"
                    className=""
                >
                    <Grid item md={4} style={{padding:'16px'}}>
                        <Box
                            border={1}
                            borderColor="grey.300"
                            borderRadius={8}
                            className="sideBySideProfile"
                        >
                            <div className="whiteBox">
                                {/* <div className="partnerBG"> */}

                                <img src={data?.logo?.url || "" } alt="Partner" />

                                {/* </div> */}
                            </div>
                        </Box>
                        <Box
                            border={1}
                            borderColor="grey.300"
                            className="grayBoard"
                            borderRadius={8}
                        >
                            <div className="webLinks">
                                <h6>Website &amp; Social profiles</h6>
                                <label>
                                    <b className="grayHeader">Website</b>
                                </label>
                                <div className="subBody" >
                                    <span>{data?.links?.website ||"www.sparkletouch.com.au"}</span>
                                </div>
                                <label>
                                    <b className="grayHeader">Linkedin</b>
                                </label>
                                <div className="subBody" >
                                    <span>{data?.links?.linkedin ||"Linkedin"}</span>
                                </div>
                                <label>
                                    <b className="grayHeader">Twitter</b>
                                </label>
                                <div className="subBody" >
                                    <span>{data?.links?.twitter ||"Twitter"}</span>
                                </div>
                                <label>
                                    <b className="grayHeader">Facebook</b>
                                </label>
                                <div className="subBody" >
                                    <span>{data?.links?.facebook ||"Facebook"}</span>
                                </div>
                                <label>
                                    <b className="grayHeader">Instagram</b>
                                </label>
                                <div className="subBody" >
                                    <span>{data?.links?.instagram ||"Instagram"}</span>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item md={8} style={{padding:'16px'}}>
                        <Box
                            border={1}
                            borderColor="grey.300"

                            borderRadius={8}
                            className="proBoard"
                        >
                            <div className="section">
                                <h6>Summary</h6>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Industries</b>
                                        </label>
                                        <div className="chip">
                                            {data?.industries?.map((item, index) => {
                                                return <Pill iconPosition='left' variant="show-all" key={index} label={item?.name || 'Label'} />
                                            })}</div>

                                    </Grid>
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Operating Locations</b>
                                        </label>

                                        {data?.operating_locations ? <div className="chip">
                                            {data?.operating_locations.split(',').map(country => (
                                                <Pill iconPosition='left' variant="show-all" label={Countries.getName(country || '', "en")} />
                                            ))}
                                            
                                        </div>: <p><div className="subBody">Not Available</div></p>}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Country (Jurisdiction)</b>
                                        </label>

                                        <div className="subBody">{data?.country || "Not Available"}</div>
                                    </Grid>
                                    <Grid item md={6}>
                                    <div >
                                            {(data?.worked_with_corporates) ? <div>
                                                <label className='subBody'>
                                                <img src={BoxTick} style={{paddingRight:'8px'}} alt={"Yes"} />Has worked with Corporates previously</label>
                                                </div>
                                                : <div>
                                                <label className='subBody'><img src={BoxCheck} style={{paddingRight:'8px'}} alt={"No"} />Has not worked with Corporates previously</label>
                                                </div>}
                                            
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="stretch"
                                        className="timingRange"
                                    >
                                        <Grid item md={6}>
                                            <label>
                                                <b className="grayHeader">Incorporated since</b>
                                            </label>

                                            <div className="subBody">{data?.start_date || "Not Available"}</div>
                                        </Grid>
                                        <Grid item md={6}>
                                            <label>
                                                <b className="grayHeader">Maturity</b>
                                            </label>

                                            <div className="subBody">{data?.maturity_level?.name || "Not Available"}</div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="stretch"
                                        className="timingRange"
                                    >
                                        <Grid item md={6}>
                                            <label>
                                                <b className="grayHeader">Clients Numbers</b>
                                            </label>

                                            <div className="subBody">{data?.client_count || "Not Available"}</div>
                                        </Grid>
                                        <Grid item md={6}>
                                            <label>
                                                <b className="grayHeader">Name of Clients</b>
                                            </label>

                                            <div className="subBody">{data?.clients || "Not Available"}</div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="stretch"
                                        className="timingRange"
                                    >
                                        <Grid item md={6}>
                                            <label>
                                                <b className="grayHeader">Went to incubator/acelerator</b>
                                            </label>

                                            <div className="subBody">{data?.went_incubator_accelerator ? "Yes":"No"}</div>
                                        </Grid>
                                        <Grid item md={6}>
                                            <label>
                                                <b className="grayHeader">Name of incubator/acelerator</b>
                                            </label>
                                            
                                            <div className="subBody">{data?.incubators_accelerators || "Not Available"}</div>
                                            
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="stretch"
                                        className="timingRange"
                                    >
                                        <Grid item md={6}>
                                            <label>
                                                <b className="grayHeader">Number of Employees</b>
                                            </label>
                                            <div className="subBody">{data?.employee_count || "Not Available"}</div>
                                        </Grid>
                                        <Grid item md={6}>
                                            <label>
                                                <b className="grayHeader">Investment Received</b>
                                            </label>
                                            
                                            <div className="subBody">{data?.investment_received_currency || "$"}{data?.investment_received ? numberWithCommas(data?.investment_received) : "Not Available"}</div>

                                        </Grid>
                                    </Grid>

                                </Grid>

                            </div>

                        </Box>
                        <Box
                            border={1}
                            borderColor="grey.300"

                            borderRadius={8}
                            className="proBoard"
                        >
                            <div className="section">
                                <h6>Personal Profile</h6>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        <div className="represtative">


                                        {data?.person_profiles?.map((item, index) => {
                                            return <Image
                                                
                                            style={{
                                                marginTop: "8px",
                                                marginBottom: "8px",
                                                width: "80px",
                                                height: "80px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                            }}
                                            src={item?.photo?.url}
                                        />
                                        })}
                                            
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>

                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">First Name</b>
                                        </label>

                                        {data?.person_profiles?.map((item, index) => {
                                            return (<div key={index} className="subBody">
                                                {item?.first_name || 'Not Available'}
                                            </div>);
                                        })}
                                    </Grid>
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Last Name</b>
                                        </label>
                                        {data?.person_profiles?.map((item, index) => {
                                            return (<div key={index} className="subBody">
                                                {item?.last_name || 'Not Available'}
                                            </div>);
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>

                                        <label>
                                            <b className="grayHeader">Role</b>
                                        </label>

                                        {data?.person_profiles?.map((item, index) => {
                                            return (<div key={index} className="subBody">
                                                {item?.role || 'Not Available'}
                                            </div>);
                                        })}
                                    </Grid>
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Contact Number</b>
                                        </label>

                                        {data?.person_profiles?.map((item, index) => {
                                            return (<div key={index} className="subBody">
                                                {item?.contact_number || 'Not Available'}
                                            </div>);
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Email</b>
                                        </label>

                                        {/* <div className="subBody">
                                            <span>test@email.com</span>
                                        </div> */}

                                        {data?.person_profiles?.map((item, index) => {
                                            return (<div key={index} className="subBody">

                                                <span>{item?.user?.email || 'Not Available'}</span>
                                            </div>);
                                        })}
                                    </Grid>
                                    <Grid item md={6}>

                                    </Grid>
                                </Grid>

                            </div>

                        </Box>
                        <Box
                            border={1}
                            borderColor="grey.300"

                            borderRadius={8}
                            className="proBoard"
                        >
                            <div className="section">
                                <h6>Demo Area</h6>


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <Grid item md={6}>
                                        <label>
                                            <b className="grayHeader">Demo URL</b>
                                        </label>

                                        <div className="subBody">

                                            <span>www.cloudtech.com/whitelabel/demo</span>
                                        </div>
                                    </Grid>
                                    <Grid item md={6}>
                                    </Grid>
                                </Grid>

                            </div>

                        </Box>
                        <Box
                            border={1}
                            borderColor="grey.300"

                            borderRadius={8}
                            className="proBoard"
                        >
                            <div className="section">
                                <h6>Testmonial</h6>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    className="timingRange"
                                >
                                    <iframe
                                        width="100%" height="414"
                                        src="https://www.youtube.com/embed/E_16YYgHIEA?controls=0">

                                    </iframe>
                                </Grid>

                            </div>

                        </Box>

                    </Grid>
                </Grid>
            </Tile>
            {/* </div> */}

        </div>
    )
}

export default StartupProfile;