import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useGetMatchMakeResultQuery, FindOneMatchResultWithRoleWithTechReadiness } from 'services/graphql/match-making-result'
import {
  Enum_Bookmark_Type,
  Enum_Bookmark_Category
} from 'generated/graphql'
import { 
  useSetBookmarkMutation, 
  useUnsetBookmarkMutation,
  useGetCurrentUserBookmarksQuery
} from 'services/graphql/bookmarks'
import './assets/styles/view-match.scss'
import Button from 'app/shared/button'
import IconLabel from 'app/shared/icon-label'
import MapPinGreenIcon from 'app/shared/assets/svgs/map-pin-green.svg';
import ThumbsUpIcon from 'app/shared/assets/svgs/thumbs-up-green.svg';
import RoundNStarIcon from 'app/shared/assets/svgs/rounded-n-star.svg';
import BoxesOddGreenIcon from 'app/shared/assets/svgs/boxes-odd-green.svg';
import PeopleGreenIcon from 'app/shared/assets/svgs/people-green.svg';
import BoxCascadeGreenIcon from 'app/shared/assets/svgs/box-cascade-green.svg';
import BoxTickGreenIcon from 'app/shared/assets/svgs/box-tick-green.svg';
import UnlockGreenIcon from 'app/shared/assets/svgs/unlock-green.svg';
import DollarGreenIcon from 'app/shared/assets/svgs/dollar-green.svg';
import UnlockAzureIcon from 'app/shared/assets/svgs/unlock-azure.svg';
import UserTickGreen from 'app/shared/assets/svgs/user-tick-green.svg'
import GlobeGreenIcon from 'app/shared/assets/svgs/globe-green.svg';
import ButtonFavourite from 'app/shared/button-favourite'
import Config from 'config';
import Pill from 'app/shared/pill'
import TickWhiteIcon from 'app/shared/assets/svgs/tick-white.svg';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import Ratings from 'app/shared/ratings';
import { Countries } from 'shared/countries';
import SpannarGreenIcon from 'app/shared/assets/svgs/spannar-green.svg';
import CogGreenIcon from 'app/shared/assets/svgs/spannar-green.svg';
import CubeGreenIcon from 'app/shared/assets/svgs/3d-box-green.svg';
import PersonShirtIcon from 'app/shared/assets/svgs/person-shirt-green.svg';
import TrashGreenIcon from 'app/shared/assets/svgs/trash-green.svg'
import TextBubbleWhiteIcon from 'app/shared/assets/svgs/text-bubble-white.svg'
import StarGreenOutlineIcon from 'app/shared/assets/svgs/star-green-outline.svg'
import MatchDial from 'app/shared/match-dial';
import Expand from 'app/shared/expand';
import TickNightSkyIcon from 'app/shared/assets/svgs/tick-nightsky.svg';
import RocketAzureIcon from 'app/shared/assets/svgs/rocket-azure.svg';
import NameCardGreenIcon from 'app/shared/assets/svgs/name-card-green.svg';
import { selectHeaderProfile } from 'features/header-profile/headerProfileSlice';
import { useSelector } from 'react-redux';
import { Image } from 'shared/image'
import { ItemRating } from 'app/shared'
import { useCredit } from 'hooks/index'
import { Enum_ConversationWithCategory } from 'features/cart/cartSlice'
import { RejectWidget } from 'app/shared/reject-widget'
import { FavouriteWidget } from 'app/shared/favourite-widget'
import { ESGRatingOptions } from 'app-constants'
import { StartConversationOrRejectButton } from 'app/shared/start-conversation-or-reject-button/start-conversation-or-reject-button'
import { trackEvent } from 'shared/mixpanel';

dayjs.extend(quarterOfYear)

const TechnologyRequestMatchDetail = () => {
  const { push } = useHistory()
  const { id, entityType }: any = useParams()
  const { data, isLoading, isSuccess } = useGetMatchMakeResultQuery(id)
  const { deductCreditRedirect } = useCredit()

  // console.log(data, isLoading, isSuccess, id, entityType)

  const technology_solution = data?.technology_solution
  const startup_profile = data?.technology_solution?.startup_profile
  
  useEffect(() => {
    if (isSuccess && data) {
      trackEvent({eventName: 'technology_solution_detail', properties: {'match': id, 'name': data?.technology_solution?.name}});
    }
  }, [isSuccess, data]);

  const imageUrl = (technology_solution?.hero_image !== undefined && technology_solution?.hero_image !== null) ? 
  technology_solution?.hero_image.url: ''

  const getYearQuarter = (date: Date) => {
    const d = dayjs(date)
    return `Q${d.quarter()} ${d.year()}`
  }

  const countries = technology_solution?.startup_profile?.operating_locations ? 
    technology_solution?.startup_profile?.operating_locations?.split(",") : [];

  const startConversationsWithAction = (action: Enum_ConversationWithCategory) => {
    if (isSuccess) {
      deductCreditRedirect({
        action,
        path: '/app/corporate/collaborate',
        targetId: technology_solution?.id,
        targetType: 'startup',
        name: technology_solution?.name ?? '',
      })
    }
  }

  const startConversation = () => {
    startConversationsWithAction(Enum_ConversationWithCategory.MATCH)
  }

  const unlockDemo = () => {
    startConversationsWithAction(Enum_ConversationWithCategory.MATCH_UNLOCK_DEMO)
  }

  const unlockTestimonial = () => {
    startConversationsWithAction(Enum_ConversationWithCategory.MATCH_UNLOCK_TESTIMONIALS)
  }

  const unlockCaseStudies = () => {
    startConversationsWithAction(Enum_ConversationWithCategory.MATCH_UNLOCK_CASE_STUDIES)
  }

  const onReject = () => {
    push(`/app/${entityType}/matches`)
  }

  const keyInfo = (
    <div className={`key-info ${technology_solution?.tested_by_alkelio ? '': 'no-recommend' }`}>
          
      <h6>About them</h6>
      {startup_profile?.country && <IconLabel icon={MapPinGreenIcon}>{Countries.getName(startup_profile?.country || '', "en")}</IconLabel>}
      {technology_solution?.industries && technology_solution?.industries.length > 0 && <IconLabel icon={BoxesOddGreenIcon}>{technology_solution?.industries.map(item => item !== null ? item.name: '').join(" / ")}</IconLabel>}
      {startup_profile?.employee_count && <IconLabel icon={PeopleGreenIcon}>{startup_profile?.employee_count}</IconLabel>}
      
      {startup_profile?.esg_rating ? 
        <IconLabel icon={StarGreenOutlineIcon}>
          ESG Rate - {ESGRatingOptions.find(({value}) => value === startup_profile?.esg_rating)?.label ?? ''}
        </IconLabel>
      : null}
      
      {startup_profile?.investment_stage && <IconLabel icon={BoxCascadeGreenIcon}>{startup_profile?.investment_stage}</IconLabel>}
      {startup_profile?.investment_received && <IconLabel icon={DollarGreenIcon}>${startup_profile?.investment_received} raised</IconLabel>}
      {startup_profile?.clients && <IconLabel icon={UserTickGreen}>{startup_profile?.clients} clients</IconLabel>}
      {startup_profile?.operating_locations && <IconLabel icon={GlobeGreenIcon}>Operating in</IconLabel>}
      {countries.map((item, i) => (
        <Pill variant="inverse" label={Countries.getName(item, "en")} icon={TickNightSkyIcon} />
      ))}
      
      <div className="mt-4 list">
        {(technology_solution?.testimonial?.quantity ?? 0) > 0 && <div className="item">
          <label>Testimonials ({technology_solution?.testimonial?.quantity ?? 0})</label>
          <span>
            <Button variant="link" className="flex-row color-java d-flex align-items-center" onClick={unlockTestimonial}>
              <div className="mb-1 icon">
                <img src={UnlockGreenIcon} alt="unlock" />
              </div>
              <span className="ps-2 text-decoration-underline text-lowercase">unlock</span>
            </Button>
          </span>
        </div>}
        {false && <div className="item">
          <label>Case studies (6)</label>
          <span>
            <Button variant="link" className="flex-row color-java d-flex align-items-center" onClick={unlockCaseStudies}>
              <div className="mb-1 icon">
                <img src={UnlockGreenIcon} alt="unlock" />
              </div>
              <span className="ps-2 text-decoration-underline text-lowercase">unlock</span>
            </Button>
          </span>
        </div>}
      </div>

      {startup_profile?.worked_with_corporates &&
      <div>
        <label>
          <img src={BoxTickGreenIcon} alt="" className="me-1" /> Has worked with corporates
        </label>
      </div>}
      
    </div>
  );

  return (<>
    {isSuccess && <div className="view-technology-request-match">
      <div className="left">
        <div className="image">
          <div className="top-right">
            <FavouriteWidget
              itemId={technology_solution?.id?? ''}
              type={Enum_Bookmark_Type.TechnologySolution} 
            />
          </div>
          <Image src={imageUrl} alt="" />
        </div>          
        {technology_solution?.tested_by_alkelio && <div className="recommended">
          <div className="body">
            <img src={ThumbsUpIcon} alt="" />
            Technology tested by ALKELIO
          </div>
          <img className="back" src={RoundNStarIcon} alt="" />
        </div>}
        {keyInfo}
      </div>
      <div className={`right ${technology_solution?.tested_by_alkelio ? 'recommended':''}`}>
        <div className="container-fluid p-0">
          
          <div className="heading">
            <div className="match-percent">
              {data?.match_rating && <MatchDial variant="light" size="lg" percent={data?.match_rating || 0} />}
            </div>
            <div className="d-flex flex-column">
              <h2 className="">{technology_solution?.name || ''}</h2>
              <ItemRating itemId={`startup-${startup_profile?.id}`} />
            </div>
          </div>
          
          <h5 className="pt-3">The Tech Solution</h5>
          <p className="mt-md-3 mb-md-4">{technology_solution?.description || ''}</p>

          <div className="d-none d-lg-block">
            <div className="flex-row d-flex">
              <img className="me-2" src={RocketAzureIcon} alt="" />
              <div className="medium-type font-size-14 font-size-md-12 line-height-160">{data?.technology_readiness_name}</div>
            </div>
          </div>

          {technology_solution?.benefits_description && <div><h5 className="pt-4">Why them?</h5>
          <p className="mt-3 mb-4">{technology_solution?.benefits_description || ''}</p></div>}

          <div className="pt-4 row">
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <h5 className="mb-0 mb-md-3">Looking to</h5>
              <div className="flex-row flex-wrap pt-2 d-flex">
                {technology_solution?.purpose_poc && <Pill label="POC" icon={TickWhiteIcon} />}
                {technology_solution?.purpose_sell && <Pill label="Sell" icon={TickWhiteIcon} />}
                {technology_solution?.purpose_lease && <Pill label="Lease" icon={TickWhiteIcon} />}
                {technology_solution?.purpose_investment && <Pill label="Investment" icon={TickWhiteIcon} />}
              </div>
            </div>
            <div className="col-12 col-md-6">
              <h5>Can Propose</h5>
              <div className="flex-row flex-wrap pt-2 d-flex">
                {technology_solution?.is_cloud && <Pill label="Cloud" icon={TickWhiteIcon} />}
                {technology_solution?.sdg_driven && <Pill label="SDG Driven" icon={TickWhiteIcon} />}
                {technology_solution?.is_gdpr && <Pill label="GDPR" icon={TickWhiteIcon} />}
                {technology_solution?.is_plugandplay && <Pill label="Plug & Play" icon={TickWhiteIcon} />}
                {technology_solution?.has_api && <Pill label="API" icon={TickWhiteIcon} />}
                {technology_solution?.has_ip && <Pill label="IP" icon={TickWhiteIcon} />}
              </div>
            </div>
          </div>

          <div className="mt-3 row">
            <div className="col-12 col-md-6">
              <Expand icon={SpannarGreenIcon} label="Use case & application">
                <>
                  {technology_solution?.business_use_cases?.map((item, i) => (item !== null && <Pill variant="show-all" key={i} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
            <div className="col-12 col-md-6">
              <Expand icon={CogGreenIcon} label="Type of technology">
                <>
                  {technology_solution?.technology_types?.map((item, i) => (item !== null && <Pill variant="show-all" key={i} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
          </div>

          <div className="mt-md-3 row">
            <div className="col-12 col-md-6">
              <Expand icon={CubeGreenIcon} label="Applicable Industries">
                <>
                  {technology_solution?.industries?.map((item, i) => (item !== null && <Pill variant="show-all" key={i} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
            <div className="col-12 col-md-6">
              <Expand icon={NameCardGreenIcon} label="Applicable departments">
                <>
                  {technology_solution?.corporate_departments?.map((item, i) => (item !== null && <Pill variant="show-all" key={i} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
          </div>

          <div className="mt-md-3 row">
            <div className="col-12">
              <Expand icon={CubeGreenIcon} label="SDG Focus">
                <>
                  {technology_solution?.sdg_objectives?.map((item, i) => (item !== null && <Pill variant="show-all" key={i} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
          </div>

          <div className="pt-4 mt-3 row">
            <div className="col">
              <h5>Demo area</h5>
              <p className="flex-row d-md-flex font-size-md-12">A demo link is available, start the conversation to 
                <Button variant="link" className="flex-row color-azure d-inline-flex d-md-flex align-items-center ms-md-2" onClick={unlockDemo}>
                  <div className="mb-1 icon">
                    <img src={UnlockAzureIcon} alt="unlock" />
                  </div>
                  <span className="mb-1 ps-2 text-decoration-underline text-lowercase base-typography font-size-md-12">unlock the demo</span>
                </Button>
              </p>
            </div>
          </div>

          {keyInfo}

          <StartConversationOrRejectButton
            itemId={technology_solution?.id ?? ''}
            itemType={Enum_Bookmark_Type.TechnologySolution}
            onReject={onReject}
            onStartConversation={startConversation}
          />

        </div>
      </div>
    </div>}
    </>
  )
}

export default TechnologyRequestMatchDetail;