import React, { useEffect } from 'react'
import Button from 'app/shared/button'
import TextBubbleWhiteIcon from 'app/shared/assets/svgs/text-bubble-white.svg'
import TrashGreenIcon from 'app/shared/assets/svgs/trash-green.svg'
import { RejectWidget } from 'app/shared/reject-widget/reject-widget'
import { Enum_Bookmark_Type } from 'generated/graphql'

import { useUsePromoCodeStartConversationMutation } from 'services/graphql/promo-code-conversation';
import { useHistory, useParams } from "react-router-dom"
import { showToast } from "features/toasts/toastsSlice";
import { useDispatch } from "react-redux";
import { useLazyGetMyConversationsQuery } from 'services/graphql/conversation'

interface Props {
  onStartConversation: () => void,
  itemId: string,
  itemType: Enum_Bookmark_Type,
  onReject: () => void
}

export const StartConversationOrRejectButton = (
  props: Props, 
  children: React.ReactNode
) => {
  const dispatch = useDispatch();
  const [ claimCode, { isLoading, isSuccess } ] = useUsePromoCodeStartConversationMutation();
  const [getConversations, fetchState] = useLazyGetMyConversationsQuery()
  const codeRef = React.useRef<HTMLInputElement>(null);
  const { push } = useHistory();
  const { id, entityType }: any = useParams()

  const onClickStartConversation = async () => {
    if (codeRef.current && codeRef.current.value) {
      // redeem code and start conversation
      const claimResult = await (await claimCode({
        code: codeRef.current.value,
        matchId: id
      }).unwrap());
      if (claimResult.result) {
        //redirect to conversations page
        await (await getConversations().unwrap());
        push(`/app/${entityType}/collaborate`);
      } else {
        // TODO show toast code invalid
        dispatch(
          showToast({
            title: "Redeem Code",
            body: "Code is invalid",
            time: null,
          })
        );
      }
    } else {
      props.onStartConversation();
    }
  }

  return (
    <div className="flex-column mt-5 border start-conversation">
      <div className="code d-flex flex-row mb-3 align-items-center">
        <div className="d-flex flex-row">
          <label className="d-flex flow-row">
            <div className="text font-size-14 mt-2 line-height-160 mr-2">Enter Conversation Code</div>
            <input type="text" className="input-text-compact" ref={codeRef} />
          </label>
        </div>
      </div>
      <div className="d-flex flex-md-row flex-column">
        <RejectWidget 
          itemId={props.itemId} 
          type={props.itemType}
          onReject={props.onReject}
          element={
            <Button className="me-md-3 mb-3 mb-md-0">
              <img src={TrashGreenIcon} className="me-2" alt="reject" />
              Reject
            </Button>
          }
        />
        <Button variant="primary" onClick={onClickStartConversation} >
          <img src={TextBubbleWhiteIcon} className="me-2" alt="start conversation" />
          Start Conversation
        </Button>
      </div>
    </div>
  );
}